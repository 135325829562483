import { ThemeOptions } from '@mui/material'
import { Colors } from 'everchain-uilibrary'

interface ThemeDefaultOptions extends ThemeOptions {
  debtColors: {
    white: string
    error: string
    success: string
  }
}

const defaultTheme: ThemeDefaultOptions = {
  palette: {
    common: {
      black: Colors.primary,
    },
    primary: {
      main: Colors.primary,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.primary,
      contrastText: Colors.white,
    },
    error: {
      main: Colors.error,
      contrastText: Colors.white,
    },
  },
  debtColors: {
    white: Colors.white,
    error: Colors.error,
    success: Colors.success,
  },
  typography: {
    fontWeightRegular: 400,
    fontWeightLight: 300,
    h1: {
      fontSize: 24,
      fontWeight: 400,
    },
    h2: {
      color: Colors.primary,
      fontSize: 18,
      fontWeight: 500,
    },
  },
  spacing: 4,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          body: {
            backgroundColor: Colors.white,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: Colors.primary,
          color: '#222',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: Colors.white,
          color: Colors.black,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          textTransform: 'none',
          fontWeight: 400,
        },
        contained: {
          textTransform: 'none',
          fontWeight: 400,
        },
        outlined: {
          fontWeight: 400,
          textTransform: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem',
          textTransform: 'none',
          color: Colors.black,
        },
        body1: { fontSize: '0.9rem' },
        body2: { fontSize: '0.9rem' },
        subtitle1: { fontSize: '1.5rem' },
        subtitle2: { fontWeight: 700 },
        h6: { fontSize: '1rem' },
      },
    },
    MuiInputBase: {
      styleOverrides: { root: { fontSize: '0.9rem' } },
    },
    MuiFormLabel: {
      styleOverrides: { root: { fontSize: '0.9rem' } },
    },
    MuiTableCell: {
      styleOverrides: { root: { fontSize: '0.9rem' } },
    },
    MuiTooltip: {
      styleOverrides: { tooltip: { fontSize: '0.75rem' } },
    },
    MuiMenuItem: {
      styleOverrides: { root: { fontSize: '0.9rem' } },
    },
    MuiTab: {
      styleOverrides: {
        labelIcon: {
          paddingTop: '2px',
          minHeight: '0px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
}

export default defaultTheme
