import { State } from '@progress/kendo-data-query'
import { useFormik } from 'formik'
import React, { useContext, useRef, useState } from 'react'

import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown'
import { AuthContext } from 'src/context/AuthenticationContext'
import hardcodeData from 'src/utils/hardcodeData'
import { Box, Button, Grid, Typography } from '@mui/material'
import {
  Grid as KendoGrid,
  GridColumn as Column,
} from '@progress/kendo-react-grid'

import { IGridColumn, formatDataToExport } from 'src/utils/common'
import { ColumnMenu } from 'src/components/Column/ColumnMenu'
import ExportExcelButton from 'src/components/ExportExcel'

import { CLIENT_PROFILES_MANAGE } from 'src/routes'
import {
  renderClickableNumberLink,
  renderTrueOrFalse,
} from 'src/utils/formatKendoColumns'
import { GET_PROFILE_REPORT_DATA } from 'src/graphql/operations/queries/report'
import { useLazyQuery } from '@apollo/client'
import { Content, Header, ReportSection } from './styles'

const initialValues = {
  status: [],
  categories: [],
}
const ProfileReport: React.FC = () => {
  const [categoriesSelected, setCategoriesSelected] = useState<string[]>([])
  const [statusSelected, setStatusSelected] = useState<string[]>([])
  const [gridData, setGridData] = useState<any>()

  const { profileClient } = useContext(AuthContext)
  const grid = useRef<any>(null)

  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: undefined,
    filter: undefined,
    sort: undefined,
  })

  const GetReportData = (values: any, kendoState: any) => {
    getProfileReportData({
      variables: {
        profileReportRequest: {
          statuses: statusSelected,
          categories: categoriesSelected,
        },
        kendoPagination: JSON.stringify(kendoState),
      },
    })
  }

  const [getProfileReportData, { loading: loadingProfileReportData }] =
    useLazyQuery<any>(GET_PROFILE_REPORT_DATA, {
      onCompleted: (data: any) => {
        setGridData(data.profileReportData)
      },
    })
  const columns: IGridColumn[] = [
    {
      title: 'Client ID',
      field: 'clientId',
      render: (props: any) =>
        renderClickableNumberLink(
          props,
          `${CLIENT_PROFILES_MANAGE}/${props.dataItem[props.field]}`,
          () => {
            localStorage.setItem(
              'profileReportCategoriesFilter',
              JSON.stringify(categoriesSelected)
            )
            localStorage.setItem(
              'profileReportStatusSelected',
              JSON.stringify(statusSelected)
            )
          }
        ),
      show: true,
      width: '120px',
    },
    {
      title: 'Client Name',
      field: 'clientName',
      show: true,
      width: '140px',
    },
    {
      title: 'Client Status',
      field: 'clientStatus',
      show: true,
      width: '120px',
    },
    {
      title: 'Client Category',
      field: 'clientCategory',
      show: true,
      width: '140px',
    },
    {
      title: 'Business Name',
      field: 'businessName',
      show: true,
      width: '140px',
    },
    {
      title: 'Business ID',
      field: 'businessId',
      show: true,
      width: '140px',
    },
    {
      title: 'Business Status',
      field: 'businessStatus',
      show: true,
      width: '120px',
    },
    {
      title: 'Business Category',
      field: 'businessCategory',
      show: true,
      width: '140px',
    },
    {
      title: 'Active Bank Account',
      field: 'activeBankAccount',
      show: true,
      width: '120px',
      render: renderTrueOrFalse,
    },
    {
      title: '# Enabled Users',
      field: 'enabledUsers',
      show: true,
      width: '120px',
    },
    {
      title: '# Disabled Users',
      field: 'disabledUsers',
      show: true,
      width: '120px',
    },
  ]

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const reportForm = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values: any) => {
      GetReportData(values, gridState)
    },
  })

  const renderCategoryOption = () => {
    const handleChange = (value: any) => {
      setCategoriesSelected(value)
      reportForm.setFieldValue('categories', value)
    }
    const data = hardcodeData.getCategories().map((x: any) => x.description)

    return (
      <MultipleSelectionDropDown
        label="Category"
        data={data}
        disable={false}
        selectionState={categoriesSelected}
        handleSelectionChange={handleChange}
        maxWidth="280px"
        minWidth="280px"
      />
    )
  }

  const renderStatusOption = () => {
    const handleChange = (value: any) => {
      setStatusSelected(value)
      reportForm.setFieldValue('buyer', value)
    }
    const data = hardcodeData.getBusinessStatus().map((x: any) => x.value)

    return (
      <MultipleSelectionDropDown
        label="Status"
        data={data}
        disable={false}
        selectionState={statusSelected}
        handleSelectionChange={handleChange}
        maxWidth="280px"
        minWidth="280px"
      />
    )
  }
  const disableUI = loadingProfileReportData
  return (
    <ReportSection>
      <Header as={Content} style={{ padding: '20px' }}>
        <Typography variant="h1" style={{ color: '#656565' }}>
          Client Profile Report
        </Typography>
      </Header>
      <form onSubmit={reportForm.handleSubmit}>
        <Box ml={5}>
          <Box
            mt={3}
            mb={3}
            display="flex"
            justifyContent="space-between"
            width={'94vw'}
          >
            <Grid item display={'flex'} direction={'row'} style={{ gap: 10 }}>
              {renderStatusOption()}
              {renderCategoryOption()}
            </Grid>
            <Grid
              container
              direction="column"
              style={{ gap: 5 }}
              xs={2}
              alignContent={'flex-end'}
            >
              <Grid
                xs={12}
                item
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Button color="primary" variant="contained" type="submit">
                  Search
                </Button>
              </Grid>
              <Grid
                xs={12}
                item
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <ExportExcelButton
                  disabled={!gridData}
                  data={formatDataToExport(
                    gridData?.profileReportResponse,
                    columns,
                    profileClient
                  )}
                  fileName="Profile_Report"
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            {disableUI && loadingPanel}
            <KendoGrid
              ref={grid}
              style={{
                width: '94vw',
              }}
              data={gridData?.profileReportResponse}
              sortable
              skip={gridState.skip}
              take={gridState.take}
              filter={gridState.filter}
              sort={gridState.sort}
              total={gridData?.total}
              onDataStateChange={(e) => {
                setGridState(e.dataState)
                GetReportData(reportForm.values, e.dataState)
              }}
            >
              {columns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      key={idx}
                      field={column.field}
                      title={column.title}
                      cell={column.render}
                      width={column.width}
                      columnMenu={ColumnMenu}
                    />
                  )
              )}
            </KendoGrid>
          </Box>
        </Box>
      </form>
    </ReportSection>
  )
}

export default ProfileReport
