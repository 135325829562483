import React from 'react'
import { Box, Typography } from '@mui/material'
import { Lock } from '@mui/icons-material'

import { AcessDiniedContent } from './styles'
import { AppBar } from 'everchain-uilibrary'
import { APP_NAME } from 'src/utils/constants'
import { useAuth } from 'src/context/AuthenticationContext'

const AccessDenied = () => {
  const authenticationObj = useAuth()
  const userId = window.localStorage.getItem('userId')

  const changePasswordlink = `${process.env.REACT_APP_AUTHORITY_URL}/account/ChangePassword?userid=${userId}&returnurl=${origin}`

  const handleOnLogout = (): void => {
    authenticationObj.logout()
  }

  const handleChangepassword = (): void => {
    window.open(changePasswordlink, '_blank')
  }

  return (
    <>
      <AppBar
        appName={APP_NAME}
        logoutProps={{ onClickLogout: handleOnLogout }}
        userProps={{
          userName:
            authenticationObj.user?.profile[
              'http://debttrader.com/claims/display_name'
            ],
          onClickChangePassword: handleChangepassword,
        }}
      />{' '}
      <AcessDiniedContent>
        <Box width={180} height={180} className="icon-fade" textAlign="center">
          <Lock
            style={{
              fontSize: 140,
            }}
          />
        </Box>
        <Typography variant="h2" className="subtitle">
          Access Denied
        </Typography>
        <Typography color="textSecondary">
          <Box component="span" fontSize={20}>
            You don't have permissions to access this page
          </Box>
        </Typography>
      </AcessDiniedContent>
    </>
  )
}

export default AccessDenied
