import {
  Box,
  CircularProgress,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material'
import React, { useContext } from 'react'
import { GroupBox } from 'src/components/GroupBox'
import { AuthContext } from 'src/context/AuthenticationContext'
import { BusinessNotificationResponse } from 'src/graphql/models/business'

interface ManageNotificationProps {
  groupedNotification: Record<string, BusinessNotificationResponse>[][]
  isLoading: boolean
  shouldReplaceBkName: boolean
  disabled: boolean
  onChangeItem: (
    groupedNotification: Record<string, BusinessNotificationResponse>[][],
    eventType: string,
    subscribed: boolean
  ) => void
}

const ManageNotification: React.FC<ManageNotificationProps> = ({
  groupedNotification,
  isLoading,
  shouldReplaceBkName = false,
  disabled = false,
  onChangeItem,
}) => {
  const { profileClient } = useContext<any>(AuthContext)
  const replaceBkName = (eventName: string) => {
    const countryCode = profileClient?.Country || process.env.REACT_APP_COUNTRY
    if (countryCode === 'UK' && shouldReplaceBkName)
      return eventName.replace('BK ', 'Insolvency ')
    return eventName
  }

  const handleChangeItems = (eventType: any) => () => {
    if (groupedNotification.some((item) => item[eventType])) {
      let subscribed: boolean = false

      const newGroupedNotification = groupedNotification.map((item: any) => {
        if (item[eventType]) {
          subscribed = !item[eventType].subscribed

          return {
            ...item,
            [eventType]: {
              ...item[eventType],
              subscribed,
            },
          }
        }
        return item
      })

      onChangeItem(newGroupedNotification, eventType, subscribed)
    }
  }

  return (
    <Box display="flex" flexDirection="column" pt={2} style={{ gap: 20 }}>
      {!isLoading ? (
        groupedNotification.map((group, k) => (
          <GroupBox
            title={
              (Object.values(group)[0] as Record<string, any>).eventSection
            }
            key={`notification-group-${k}`}
          >
            <Box display="flex" flexDirection="column" style={{ gap: 10 }}>
              {Object.entries(group).map(([key, item]: any[]) => (
                <Box key={key}>
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={disabled}
                        color="primary"
                        onChange={handleChangeItems(key)}
                        checked={Boolean(item.subscribed)}
                        value={item.subscribed}
                      />
                    }
                    label={
                      <>
                        <Typography component="strong">
                          {replaceBkName(item.eventLabel)}
                        </Typography>
                        <Typography color="textSecondary">
                          {replaceBkName(item.eventTypeDescription)}
                        </Typography>
                      </>
                    }
                  />
                </Box>
              ))}
            </Box>
          </GroupBox>
        ))
      ) : (
        <Box p={5} display="flex" alignItems="center">
          <Box mr={3}>
            <Typography>Loading</Typography>
          </Box>
          <CircularProgress size={16} />
        </Box>
      )}
    </Box>
  )
}

export default ManageNotification
