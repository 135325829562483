import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AbilityContext } from 'src/context/Can'
import {
  CLIENT_PROFILES,
  MANAGE_PERMISSIONS,
  COMMISSIONS,
  LOGS,
  KNOWLEDGE_BASE,
  HELP_REQUEST,
  INTERNAL_USERS,
  INTERNAL_NOTIFICATION,
  INTERNAL_EDIT_NOTIFICATION_TEXT,
  PROFILE_REPORT,
} from 'src/routes'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AuthContext } from 'src/context/AuthenticationContext'
import { useQuery } from '@apollo/client'

import { commonMutation } from 'src/graphql/operations/mutations'
import { GET_MENU_CONTROL } from 'src/graphql/operations/queries/Communs'
import { INTERNAL } from 'src/configs/AuthService'
import {
  Icon,
  SideMenu,
  SideMenuItem,
  SideMenuSubItem,
} from 'everchain-uilibrary'
import ContactDialog from 'src/components/Dialogs/ContactDialog'

const Menus = () => {
  const history = useHistory()
  const [pageUrl, setPageUrl] = useState(history.location.pathname)
  const ability = useContext(AbilityContext)
  const { userPermissions } = useContext(AuthContext)
  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'
  const isInternal = userPermissions.type.toLowerCase() === INTERNAL
  const [openContactInfo, setOpenContactInfo] = useState<boolean>(false)

  const isURL = (paths: string[]) => {
    let result = false
    for (const p of paths) {
      if (pageUrl.indexOf(p) !== -1) {
        result = true
        break
      }
    }
    return result
  }

  const { data: menusControlData } = useQuery<any>(GET_MENU_CONTROL)

  const menusControlConfig = menusControlData?.menuControl.menuControl
  const { upadateMenuControl } = commonMutation

  const handleMenuDispatch = (type: string): void => {
    upadateMenuControl({
      menuControl: {
        managePermissionOpen: type === 'managePermissionOpen',
        clientProfileOpen: type === 'clientProfileOpen',
        commissionsOpen: type === 'commissionsOpen',
        contactOpen: type === 'contactOpen',
        logOpen: type === 'logOpen',
        internalusersOpen: type === 'internalusersOpen',
        faqOpen: type === 'faqOpen',
        otherAppsOpen: type === 'otherAppsOpen',
      },
    })
  }

  const internalUserPermissionUrls = [
    INTERNAL_USERS,
    INTERNAL_NOTIFICATION,
    INTERNAL_EDIT_NOTIFICATION_TEXT,
  ]

  const handleInternalRedirect = (uri: string) => {
    history.push(uri)
  }

  const location = useLocation()

  useEffect(() => {
    setPageUrl(location.pathname)
  }, [location])

  return (
    <>
      <SideMenu>
        <SideMenuItem
          id="menu-client-profile"
          title="Client Profiles"
          onClick={() => handleInternalRedirect(CLIENT_PROFILES)}
          icon={<Icon name="People" />}
          defaultOpen={pageUrl.indexOf(CLIENT_PROFILES) !== -1}
        />
        {isInternal && (
          <SideMenuItem
            id="menu-report"
            title="Report"
            onClick={() => handleInternalRedirect(PROFILE_REPORT)}
            icon={<Icon name="FindInPage" />}
            defaultOpen={pageUrl.indexOf(PROFILE_REPORT) !== -1}
          />
        )}
        {ability.can(
          PermissionCodeAccess.MANAGE_EXTERNAL_PERMISSION,
          'any'
        ) && (
          <SideMenuItem
            id="menu-manage-permission"
            title="Manage Permissions"
            onClick={() => handleInternalRedirect(MANAGE_PERMISSIONS)}
            icon={<Icon name="Work" />}
            defaultOpen={pageUrl.indexOf(MANAGE_PERMISSIONS) !== -1}
          />
        )}
        {isInternal &&
          (ability.can(
            PermissionCodeAccess.CLIENT_EXTERNAL_USER_PERMISSION,
            'any'
          ) ||
            ability.can(
              PermissionCodeAccess.CLIENT_BUSINESS_NOTIFICATION_PERMISSION,
              'any'
            )) && (
            <SideMenuItem
              id="menu-internal-user"
              title="Internal Users"
              onClick={(): void => {
                if (!isURL(internalUserPermissionUrls)) {
                  if (
                    ability.can(
                      PermissionCodeAccess.CLIENT_INTERNAL_USER_PERMISSION,
                      'any'
                    )
                  ) {
                    handleInternalRedirect(internalUserPermissionUrls[0])
                  } else {
                    handleInternalRedirect(internalUserPermissionUrls[1])
                  }
                }
              }}
              icon={<Icon name="AssignmentInd" />}
              defaultOpen={isURL(internalUserPermissionUrls)}
            >
              {(ability.can(
                PermissionCodeAccess.CLIENT_INTERNAL_USER_PERMISSION,
                'any'
              ) ||
                ability.can(
                  PermissionCodeAccess.MANAGE_INTERNAL_PERMISSION,
                  'any'
                )) && (
                <SideMenuSubItem
                  id="sub-menu-internal-user"
                  title="Internal Users"
                  onClick={() =>
                    handleInternalRedirect(internalUserPermissionUrls[0])
                  }
                  icon={<Icon name="AssignmentInd" />}
                />
              )}
              {ability.can(
                PermissionCodeAccess.CLIENT_BUSINESS_NOTIFICATION_PERMISSION,
                'any'
              ) && (
                <>
                  <SideMenuSubItem
                    id="sub-menu-notification"
                    title="Notification"
                    onClick={() =>
                      handleInternalRedirect(internalUserPermissionUrls[1])
                    }
                    defaultOpen={
                      pageUrl.indexOf(internalUserPermissionUrls[1]) !== -1
                    }
                    icon={<Icon name="NotificationImportantOutlined" />}
                  />
                  {!process.env.REACT_APP_HIDE_INTERNAL_NOTIFICATION_MENU && (
                    <SideMenuSubItem
                      id="sub-menu-edit-notification-text"
                      title="Edit Notifications Text"
                      onClick={() =>
                        handleInternalRedirect(internalUserPermissionUrls[2])
                      }
                      defaultOpen={
                        pageUrl.indexOf(internalUserPermissionUrls[2]) !== -1
                      }
                      icon={<Icon name="EditOutlined" />}
                    />
                  )}
                </>
              )}
            </SideMenuItem>
          )}
        {ability.can(PermissionCodeAccess.LOG_PERMISSION, 'any') && (
          <SideMenuItem
            id="menu-log"
            title="Logs"
            onClick={() => handleInternalRedirect(LOGS)}
            icon={<Icon name="Book" />}
            defaultOpen={pageUrl.indexOf(LOGS) !== -1}
          />
        )}
        {!process.env.REACT_APP_HIDE_COMMISSION_MENU &&
          (ability.can(PermissionCodeAccess.MANAGE_COMMISSION, 'any') ||
            ability.can(PermissionCodeAccess.VIEW_COMMISSION, 'any')) && (
            <SideMenuItem
              id="menu-comission"
              title="Commissions"
              onClick={() => handleInternalRedirect(COMMISSIONS)}
              icon={<Icon name="Payment" />}
              defaultOpen={pageUrl.indexOf(COMMISSIONS) !== -1}
            />
          )}
        {(isSeller || isBuyer) && (
          <SideMenuItem
            id="menu-contact"
            title="Contact"
            onClick={() => setOpenContactInfo(true)}
            icon={<Icon name="RecordVoiceOver" />}
          />
        )}
        {!process.env.REACT_APP_HIDE_FAQ_MENU && (
          <SideMenuItem
            id="menu-faq"
            title="FAQ"
            onClick={() => handleMenuDispatch('faqOpen')}
            icon={<Icon name="Forum" />}
            defaultOpen={menusControlConfig?.faqOpen}
          >
            <SideMenuSubItem
              id="sub-menu-knowledge-base"
              title="Knowledge Base"
              onClick={() => window.open(KNOWLEDGE_BASE, '_blank')}
              icon={<Icon name="MenuBook" />}
            />
            <SideMenuSubItem
              id="sub-menu-help-request"
              title="Help Request"
              onClick={() => window.open(HELP_REQUEST, '_blank')}
              icon={<Icon name="HelpRounded" />}
            />
          </SideMenuItem>
        )}
        {!process.env.REACT_APP_HIDE_CMS_MENU &&
          (userPermissions.marketPlacePermission ||
            userPermissions.cmsPermission) && (
            <SideMenuItem
              id="menu-other-apps"
              title="Other Apps"
              onClick={() => handleMenuDispatch('otherAppsOpen')}
              icon={<Icon name="Apps" />}
              defaultOpen={menusControlConfig?.otherAppsOpen}
            >
              {userPermissions.marketPlacePermission && (
                <SideMenuSubItem
                  id="sub-menu-marketplace"
                  title="Market"
                  onClick={() =>
                    window.open(
                      process.env.REACT_APP_MARKETPLACE_SITE,
                      '_blank'
                    )
                  }
                  icon={<Icon name="AccountBalanceWallet" />}
                />
              )}
              {userPermissions.cmsPermission && (
                <SideMenuSubItem
                  id="sub-menu-complaince"
                  title="Comply"
                  onClick={() =>
                    window.open(process.env.REACT_APP_CMS_SITE, '_blank')
                  }
                  icon={<Icon name="PlaylistAddCheckOutlined" />}
                />
              )}
            </SideMenuItem>
          )}
      </SideMenu>
      <ContactDialog open={openContactInfo} setOpen={setOpenContactInfo} />
    </>
  )
}

export default Menus
