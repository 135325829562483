import { Box } from '@mui/material'
import { Colors } from 'everchain-uilibrary'
import styled from 'styled-components'

export const BuyerAccessActions = styled.div`
  background-color: ${Colors.mediumGray};
`
export const BoxBreakSpace = styled(Box)`
  white-space: break-spaces;
`
