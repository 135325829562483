import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from '@mui/material'
import hardCodeData from 'src/utils/hardcodeData'

import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { Button, Paper, UploadDragDrop } from 'everchain-uilibrary'
import { useHistory, useParams } from 'react-router-dom'
import MultipleSelectionDropDown from '../MultipleSelectionDropDown'
import { AssetType, BuyerRelated } from 'src/graphql/models/businessRecovery'
import {
  getAssetTypes,
  getBuyerRelated,
  saveAgreementTemplate,
} from 'src/infra/api/services/client'
import { useFormik } from 'formik'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import * as Yup from 'yup'
import Header from '../Header'
import { AGGREMENT_TEMPLATE_DETAIL } from 'src/routes'
import { downloadAgreementFile } from 'src/infra/api/services/agreementTemplates'

const AgreementTemplatesForm = () => {
  const initialValues = {
    name: '',
    assetTypes: '',
    buyers: '',
    type: 'psa',
    file: undefined,
    allowedForForwardFlow: false,
    useAsCustomPSA: false,
  }
  const agreementValidationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    buyers: Yup.array()
      .of(Yup.string())
      .when('type', {
        is: (value: string) => value !== 'nda',
        then: Yup.array().of(Yup.string()).required('Required'),
        otherwise: Yup.array().of(Yup.string()),
      }),
    assetTypes: Yup.array()
      .of(Yup.string())
      .when('type', {
        is: (value: string) => value !== 'nda',
        then: Yup.array().of(Yup.string()).required('Required'),
        otherwise: Yup.array().of(Yup.string()),
      }),
  })
  const { sellerId, clientId, tabSelected } = useParams<any>()
  const queryClient = useQueryClient()
  const { data: assetType, isFetching: assetTypeLoading } = useCustomQuery<
    AssetType[]
  >(
    ['getAssetType'],
    async () => {
      return getAssetTypes().then((result: AssetType[]) => {
        result.unshift({ name: 'All', id: -1, assetTypeFee: 0 })
        return result
      })
    },
    {
      enabled: true,
      cacheTime: 0,
    }
  )
  const { data: buyers, isFetching: buyersLoading } = useCustomQuery<
    BuyerRelated[]
  >(
    ['getBuyersRelated'],
    async () => {
      return getBuyerRelated(sellerId).then((result: BuyerRelated[]) => {
        return result
      })
    },
    {
      enabled: true,
      cacheTime: 0,
    }
  )

  const validate = (values: any) => {
    const errors: any = {}

    if (values.file == null) errors.file = 'Required'
    return errors
  }
  const history = useHistory()
  const agreementForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    isInitialValid: false,
    validate,
    validationSchema: agreementValidationSchema,
    onSubmit: (values: any) => {
      const dbListBuyers = buyers ?? []
      const selectedBuyersIds =
        buyersSelected.length > 0
          ? buyersSelected.includes('All')
            ? ['All']
            : dbListBuyers
                .filter((buyer) => buyersSelected.includes(buyer.name))
                .map((buyer) => buyer.id)
          : dbListBuyers.map((buyer) => buyer.id)

      const dbListAssetType = assetType ?? []
      const selectedAssetTypeIds =
        assetTypeSelected.length > 0
          ? assetTypeSelected.includes('All')
            ? ['All']
            : dbListAssetType
                .filter((asset) => assetTypeSelected.includes(asset.name))
                .map((asset) => asset.id.toString())
          : dbListAssetType.map((asset) => asset.id.toString())

      saveAgreementTemplateEvent.mutate({
        type: values.type,
        name: values.name,
        buyerIds: selectedBuyersIds,
        assetTypes: selectedAssetTypeIds,
        file: values.file,
        allowedForForwardFlow: values.allowedForForwardFlow,
        useAsCustomPSA: values.useAsCustomPSA,
      })
    },
  })
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const downloadTemplateEvent = useMutation({
    mutationFn: () =>
      downloadAgreementFile(undefined, agreementForm.values.file, sellerId),
    onSuccess: (data: any) => {
      if (data) {
        const { fileContentBase64, fileContentType, fileDownloadName } = data
        const linkSource = `data:${fileContentType};base64,${fileContentBase64}`
        const downloadLink = document.createElement('a')

        downloadLink.href = linkSource
        downloadLink.download = fileDownloadName
        downloadLink.click()
      }
      setIsLoading(false)
    },
    onError: () => {
      setIsLoading(false)
      enqueueSnackbar('Error when download Agreement template.', notifyError)
    },
  })
  const saveAgreementTemplateEvent = useMutation({
    mutationFn: (request: any) =>
      saveAgreementTemplate(
        sellerId,
        request.buyerIds,
        request.type,
        request.name,
        request.assetTypes,
        request.file,
        request.allowedForForwardFlow,
        request.useAsCustomPSA
      ),
    onSuccess: (data: any) => {
      queryClient.refetchQueries({
        queryKey: ['getAgreementTemplates'],
      })

      setIsLoading(false)
      enqueueSnackbar('Agreement template created successfully.', notifySuccess)
      history.goBack()
    },
    onError: (error: any) => {
      if (error?.data?.type === 'validationError')
        enqueueSnackbar(error?.data?.detail?.trim(), notifyError)
      else
        enqueueSnackbar('Error when creating Agreement template.', notifyError)
      setIsLoading(false)
    },
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [assetTypeSelected, setAssetTypeSelected] = useState<string[]>([])
  const [buyersSelected, setBuyersSelected] = useState<string[]>([])

  const renderAssetTypeOption = () => {
    const assetTypes =
      assetType != null ? assetType?.map((x: AssetType) => x.name) : []

    const handleChange = (value: any, event: any) => {
      if (
        event === 'AllSelect' &&
        value.filter((item: any) => item !== 'All').length ===
          assetTypeSelected.length
      ) {
        setAssetTypeSelected([])
        agreementForm.setFieldValue('assetTypes', [])
      } else if (
        event === 'AllSelect' &&
        value.length > assetTypeSelected.length
      ) {
        const items = value.filter((item: any) => item !== 'All')
        setAssetTypeSelected(items)
        agreementForm.setFieldValue('assetTypes', items)
      } else if (event !== 'AllSelect') {
        if (assetTypeSelected.includes('All') && value.length > 1) {
          const items = value.filter((item: any) => item !== 'All')
          setAssetTypeSelected(items)
          agreementForm.setFieldValue(' assetTypes', items)
        } else if (value.includes('All')) {
          setAssetTypeSelected(['All'])
          agreementForm.setFieldValue('assetTypes', ['All'])
        } else {
          setAssetTypeSelected(value)
          agreementForm.setFieldValue('assetTypes', value)
        }
      }
    }
    return (
      <MultipleSelectionDropDown
        label="Asset Type"
        selectionState={assetTypeSelected}
        handleSelectionChange={handleChange}
        disable={assetTypeLoading}
        data={assetTypes}
        maxWidth="300px"
        minWidth="300px"
      />
    )
  }
  const renderBuyersOption = () => {
    const buyersRelated =
      buyers != null ? buyers?.map((x: BuyerRelated) => x.name) : []
    buyersRelated.unshift('All')
    const handleChange = (value: any, event: any) => {
      if (
        event === 'AllSelect' &&
        value.filter((item: any) => item !== 'All').length ===
          buyersSelected.length
      ) {
        setBuyersSelected([])
        agreementForm.setFieldValue('buyers', [])
      } else if (
        event === 'AllSelect' &&
        value.length > buyersSelected.length
      ) {
        const items = value.filter((item: any) => item !== 'All')
        setBuyersSelected(items)
        agreementForm.setFieldValue('buyers', items)
      } else if (event !== 'AllSelect') {
        if (buyersSelected.includes('All') && value.length > 1) {
          const items = value.filter((item: any) => item !== 'All')
          setBuyersSelected(items)
          agreementForm.setFieldValue('buyers', items)
        } else if (value.includes('All')) {
          setBuyersSelected(['All'])
          agreementForm.setFieldValue('buyers', ['All'])
        } else {
          setBuyersSelected(value)
          agreementForm.setFieldValue('buyers', value)
        }
      }
    }
    return (
      <MultipleSelectionDropDown
        label="Buyer"
        selectionState={buyersSelected}
        handleSelectionChange={handleChange}
        disable={buyersLoading}
        data={buyersRelated}
        maxWidth="300px"
        minWidth="300px"
      />
    )
  }

  useEffect(() => {
    if (localStorage.getItem('agreementTemplateType') !== null) {
      agreementForm.setFieldValue(
        'type',
        localStorage.getItem('agreementTemplateType')
      )
    }
  }, [])

  return (
    <form onSubmit={agreementForm.handleSubmit}>
      <Box p={5}>
        <Header hideAvatar title="Create a new Agreement Template" />
      </Box>
      <Box p={5}>
        <Paper style={{ padding: 15 }} id="agreement-details">
          <Grid mt={2} container spacing={4}>
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                label="Type"
                select
                disabled={true}
                name="type"
                error={!!agreementForm.errors.type}
                onChange={(e: any) =>
                  agreementForm.setFieldValue('type', e?.target?.value)
                }
                value={agreementForm.values.type}
              >
                {hardCodeData.getAgreementTemplates().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={agreementForm.handleChange}
                value={agreementForm.values.name}
              ></TextField>
            </Grid>
            {agreementForm.values.type !== 'nda' && (
              <>
                <Grid item xs={12} lg={3}>
                  {renderAssetTypeOption()}
                </Grid>
                <Grid item xs={12} lg={3}>
                  {renderBuyersOption()}
                </Grid>

                <Grid item lg={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={agreementForm.handleChange}
                        name="allowedForForwardFlow"
                        checked={agreementForm.values.allowedForForwardFlow}
                        value={agreementForm.values.allowedForForwardFlow}
                        disabled={isLoading}
                      />
                    }
                    label="Allowed for Forward Flow"
                  />
                </Grid>
              </>
            )}
            {agreementForm.values.type === 'psa' && (
              <Grid item lg={3}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      onChange={agreementForm.handleChange}
                      name="useAsCustomPSA"
                      checked={agreementForm.values.useAsCustomPSA}
                      value={agreementForm.values.useAsCustomPSA}
                      disabled={isLoading}
                    />
                  }
                  label="Use As Custom PSA"
                />
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              <UploadDragDrop
                files={
                  agreementForm.values.file ? [agreementForm.values.file] : []
                }
                setFiles={(file) =>
                  agreementForm.setFieldValue('file', file?.[0])
                }
                hideUploadButton
                multiple={false}
                text={
                  'or Drop our ' +
                  agreementForm.values.type?.toUpperCase() +
                  ' file here'
                }
                accept={{
                  'application/msword': ['.doc', '.docx'],
                }}
              />
            </Grid>
            <Grid item lg={12}>
              <Button
                disabled={!agreementForm.values.file || isLoading}
                onClick={() => {
                  setIsLoading(true)
                  downloadTemplateEvent.mutate()
                }}
              >
                Test {agreementForm.values.type?.toUpperCase()} Template
                Document
              </Button>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Button
                onClick={(e: any) => {
                  e.preventDefault()
                  setIsLoading(true)
                  agreementForm.handleSubmit()
                }}
                disabled={!agreementForm.isValid || isLoading}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </form>
  )
}

AgreementTemplatesForm.defaultProps = {
  sellerId: null,
}
export default AgreementTemplatesForm
