import { BACKEND_URL } from '../../axios-wrapper/httpClient'

const businessPrefix = '/business.'

export const BusinessGetFee = `${BACKEND_URL}${businessPrefix}getBusinessFee`
export const BusinessAddFee = `${BACKEND_URL}${businessPrefix}addBusinessFee`
export const BusinessUpdateFee = `${BACKEND_URL}${businessPrefix}updateBusinessFee`
export const BusinessGetFeeEnabled = `${BACKEND_URL}${businessPrefix}getBusinessFeeEnabled`
export const BusinessSetFeeEnabled = `${BACKEND_URL}${businessPrefix}updateBusinessFeeEnabled`
export const BusinessDeleteFee = `${BACKEND_URL}${businessPrefix}deleteBusinessFee`
export const BusinessGetFeatureFlag = `${BACKEND_URL}${businessPrefix}getBusinessFeatureFlag`
export const BusinessesWithClientAssoction = `${BACKEND_URL}${businessPrefix}getBusinessesWithClientAssoction`
