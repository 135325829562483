export interface BusinessNotificationItem {
  eventLabel: string
  eventSection: string
  eventType: string
  eventTypeDescription: string
  id: number
  subscribed: boolean
}

export interface BusinessNotificationResponse {
  eventLabel: string
  eventSection: string
  eventType: string
  eventTypeDescription: string
  subscribed: boolean
}

export interface UserResponse {
  displayName: string
  emailAddress: string
  enableTextMessaging: boolean
  firstName: string
  id: number
  lastName: string
  lastUpdated: Date
  lastUpdatedSqlId: string
  lastUpdatedUserId: number
  lastUpdatedUserName: String
  profilePictureUrl: String
  status: string
  tenantId: number
  textMessagingPhoneNumber: string
  title: string
  voicePhoneNumber: string
}

export interface NotificationSummaryResponse {
  getNotificationSummary: {
    listEmails: string[]
    listUsers: UserResponse[]
  }
}

export interface NotificationRecipientsResponse {
  getInternalNotificationRecipients: {
    listEmails: string[]
    listUsers: UserResponse[]
  }
}

export type GetNotificationDetail = {
  getNotificationDetail: BusinessNotificationResponse[]
}

export type GetBusinessNotificationEventList = {
  getBusinessNotificationEventList: BusinessNotificationItem[]
}

export type GetInternalNotificationDetail = {
  getInternalNotificationDetail: BusinessNotificationResponse[]
}

export interface BusinessInternalNotificationItem {
  eventLabel: string
  eventSection: string
  eventType: string
  eventTypeDescription: string
  id: number
  subscribed: boolean
}

export type GetBusinessInternalNotificationEventList = {
  getBusinessInternalNotificationEventList: BusinessInternalNotificationItem[]
}

export const formatBusinessNotificationGroupFields = (
  fields: BusinessNotificationResponse[]
): Record<string, BusinessNotificationResponse>[][] => {
  const eventSectionOrder = [
    'Portfolio',
    'Post-Sale',
    'BOS / PSA',
    'Complaints',
    'Compliance',
  ]

  const eventTypeOrder = [
    // Portfolio
    'portfolio-upload-failed',
    'portfolio-upload-succeeded',
    'portfolio-accounts-removed',
    'portfolio-mapping-warning',
    'portfolio-cot-error-warning',
    'portfolio-scrub-processed',
    'portfolio-listed',
    'bid-placed',
    'portfolio-auctionclosed',
    'bid-accepted',
    'bid-rejected',
    'portfolio-refreshed',
    'bk-forward-flow-confirmation',
    'invoices',
    'portfolio-pending-psa',
    'portfolio-final-signature-uploaded',
    'portfolio-buyer-funding-confirmation',
    'portfolio-seller-funding-confirmation',
    'portfolio-final-funding-confirmation',
    'final-sold-file-available',
    'forward-flow-expiration',
    'seller-upload-template-changed',

    // Post-Sale
    'postsale-summary',
    'postsale-bulk-request-submitted',
    'postsale-request-user-assigned',
    'postsale-file-modified',
    'request-escalation',

    // BOS / PSA
    'psa-release',
    'psa-redline-release',
    'psa-pending-redline-review',
    'psa-draft-approval',
    'psa-final-signature',
    'final-psa-approved',
    'psa-e-signature-sent',
    'bos-available',
    'bos-creation',
    'bos-e-signature-sent',
    'bos-review',
    'bos-executed',

    // Complaints
    'complaints',
    'complaint-info-request',
    'complaint-commented',
    'complaints-stale',

    // Compliance
    'complete-certification',
    'document-rejected',
    'document-accepted',
    'expiring-documents',
    'new-document-requested',
    'new-license-required',
    'start-certification',
    'sop-access',
  ]

  const sortedFields = [...fields].sort((a, b) => {
    const indexA = eventSectionOrder.indexOf(a.eventSection)
    const indexB = eventSectionOrder.indexOf(b.eventSection)

    const adjustedIndexA = indexA === -1 ? Infinity : indexA
    const adjustedIndexB = indexB === -1 ? Infinity : indexB
    const sectionComparison = adjustedIndexA - adjustedIndexB

    if (sectionComparison === 0) {
      const typeIndexA = eventTypeOrder.indexOf(a.eventType)
      const typeIndexB = eventTypeOrder.indexOf(b.eventType)

      const typeAdjustedIndexA = typeIndexA === -1 ? Infinity : typeIndexA
      const typeAdjustedIndexB = typeIndexB === -1 ? Infinity : typeIndexB

      return typeAdjustedIndexA - typeAdjustedIndexB
    }

    return sectionComparison
  })

  const groupedFields = sortedFields.reduce((acc: any, item) => {
    if (
      !acc.length ||
      (
        Object.values(acc[acc.length - 1] as Record<string, any>)[0] as Record<
          string,
          any
        >
      ).eventSection !== item.eventSection
    ) {
      acc.push({ [item.eventType]: item })
    } else {
      acc[acc.length - 1] = { ...acc[acc.length - 1], [item.eventType]: item }
    }
    return acc
  }, [])

  return groupedFields
}
