import React, { useContext, useState } from 'react'
import * as Yup from 'yup'
import {
  Box,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Skeleton,
  Switch,
  TextField,
} from '@mui/material'
import { State } from '@progress/kendo-data-query'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  Button,
  DataTable,
  Flex,
  ModalDialog,
  Typography,
  renderCheckbox,
} from 'everchain-uilibrary'
import {
  addOrRemoveFileUnmaskWithColumns,
  getSellerUploadTemplateFileUnmaskBySeller,
} from 'src/infra/api/services/fileUnmask'
import {
  FileUnmaskColumnsRequest,
  FileUnmaskWithColumnsRequest,
  SellerUploadTemplateFileUnmaskDataResponse,
} from 'src/infra/api/models/fileUnmask'
import { getPortfolioTemplateFileMap } from 'src/infra/api/services/portfolio'
import { PortfolioTemplateFileMapResponse } from 'src/infra/api/models/portfolio'
import { notistackOptions } from 'src/configs/notistackOptions'
import { enqueueSnackbar } from 'notistack'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import {
  ExportFieldDefinitionResponse,
  ExportFileDefinition,
} from 'src/infra/api/models/dataManager'
import {
  getAllPortfolioTypes,
  getFieldsByFileDefinitionId,
  getFileDefinitionByPortfolioType,
  updateFieldDefinition,
} from 'src/infra/api/services/dataManager'
import { PortfolioType } from 'src/graphql/models/buyer'
import { useFormik } from 'formik'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'

interface FileUnmaskListProps {
  sellerId?: string
}

const FileUnmaskList: React.FC<FileUnmaskListProps> = ({ sellerId }) => {
  const [openEditModal, setOpenEditModal] = useState<boolean>(false)
  const [openEditFieldModal, setOpenEditFieldModal] = useState<boolean>(false)
  const [editTemplateId, setEditTemplateId] = useState<number>()
  const [editField, setEditField] = useState<any>()
  const [portfolioTypeId, setPortfolioTypeId] = useState<number>()
  const [fileDefinitionId, setFileDefinitionId] = useState<number>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [columnsRequest, setColumnsRequest] = useState<
    FileUnmaskColumnsRequest[]
  >([])
  const [unmaskWithColumnsRequest, setUnmaskWithColumnsRequest] =
    useState<FileUnmaskWithColumnsRequest>()
  const ability = useContext(AbilityContext)
  const [unmaksEntireFile, setUnmaksEntireFile] = useState<boolean>(false)
  const hasManageFileMapPermission = ability.can(
    PermissionCodeAccess.PORTFOLIO_DATA_MANAGER_MANAGE_VALIDATION,
    'any'
  )
  const hasManageUnmaskedDataPermission = ability.can(
    PermissionCodeAccess.PORTFOLIO_DATA_MANAGER_MANAGE_UNMASKED_DATA,
    'any'
  )
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const [validationManagerGridState, setValidationManagerGridState] =
    useState<State>({
      skip: 0,
      take: 25,
      filter: undefined,
      sort: undefined,
    })
  const gridStateEdit = {
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  }

  const initialValuesData = {
    exportFieldDefinitionName: '',
    isRequired: false,
    exportFieldFormat: null,
  }

  const pageTypes = {
    dataManager: 'data_manager',
    validationManager: 'validation_manager',
  }

  const dateFormats = ['MM/dd/yyyy', 'dd/MM/yyyy', 'yyyy/MM/dd']

  const [pageType, setPageType] = useState<string>(pageTypes.dataManager)

  const fieldSchema = Yup.object().shape({
    exportFieldDefinitionName: Yup.string().required('Required'),
    isRequired: Yup.boolean(),
    exportFieldFormat: Yup.string().nullable(),
  })

  const [initialValues, setInitialValues] = useState(initialValuesData)

  const gridColumns: any[] = [
    {
      field: 'sellerUploadTemplateId',
      title: 'Template ID',
      width: 180,
      show: true,
    },
    {
      field: 'sellerUploadTemplateName',
      title: 'Template Name',
      width: 180,
      show: true,
    },
    {
      field: '',
      title: 'Unmasked Columns',
      width: 180,
      show: true,
      render: (props: any) => {
        const joinedColumns = props.dataItem?.fileUnmask?.allColumnsSelected
          ? 'All'
          : props.dataItem?.fileUnmask?.fileUnmaskColumns
              .map((x: any) => x.columnName)
              .join(', ')

        return <td>{joinedColumns}</td>
      },
    },
  ]

  const validationManagerGridColumns: any[] = [
    {
      field: 'exportFieldDefinitionName',
      title: 'Field Name',
      width: 180,
      show: true,
    },
    {
      field: 'usageInfo',
      title: 'Usage Info',
      width: 180,
      show: true,
    },
    {
      field: 'isMandatory',
      title: 'Mandatory',
      width: 180,
      show: true,
      filter: 'boolean',
      render: (props: any) => {
        const description = props.dataItem.isMandatory ? 'Yes' : 'No'
        return <td>{description}</td>
      },
    },
    {
      field: 'isRequired',
      title: 'Required',
      width: 180,
      show: true,
      filter: 'boolean',
      render: (props: any) => {
        const description = props.dataItem.isRequired ? 'Yes' : 'No'
        return <td>{description}</td>
      },
    },
    {
      field: 'exportFieldFormat',
      title: 'Format',
      width: 180,
      show: true,
      render: (props: any) => {
        return <td>{props.dataItem.exportFieldFormat?.toUpperCase()}</td>
      },
    },
  ]

  const gridColumnsEdit: any[] = [
    {
      field: 'headerName',
      title: 'Column Name',
      width: 180,
      show: true,
    },
    {
      title: 'Unmasked',
      width: 180,
      show: true,
      render: (props: any) =>
        renderCheckbox(
          unmaksEntireFile || !hasManageUnmaskedDataPermission,
          checkUnmaskedChecked(props.dataItem?.headerName),
          (checked) =>
            handleOnChangeUnmasked(checked, props.dataItem?.headerName)
        ),
    },
  ]

  const reactQueryClient = useQueryClient()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const checkUnmaskedChecked = (columnName: string): boolean => {
    return (
      columnsRequest.some((x) => x.unmask && x.columnName === columnName) ||
      (!!fileUnmaskData?.sellerUploadTemplateFileUnmask.some(
        (template) =>
          template.sellerUploadTemplateId === editTemplateId &&
          template.fileUnmask?.fileUnmaskColumns?.some(
            (column) => column.columnName === columnName
          )
      ) &&
        !columnsRequest.some((x) => !x.unmask && x.columnName === columnName))
    )
  }

  const handleOnChangeUnmasked = (checked: boolean, columnName: string) => {
    setColumnsRequest((prev) => {
      const columnExists = prev.some((item) => item.columnName === columnName)

      if (columnExists) {
        return prev.map((item) =>
          item.columnName === columnName ? { ...item, unmask: checked } : item
        )
      } else {
        return [...prev, { columnName, unmask: checked }]
      }
    })
  }

  const handleSaveEditUnmask = () => {
    if (sellerId && editTemplateId) {
      setUnmaskWithColumnsRequest({
        sellerId: sellerId,
        sellerUploadTemplateId: editTemplateId,
        allColumnsSelected: unmaksEntireFile,
        unmaskColumnsRequest: columnsRequest,
      })
    }
  }

  const { data: fileUnmaskData, isFetching: fileUnmaskDataLoading } =
    useCustomQuery<SellerUploadTemplateFileUnmaskDataResponse>(
      ['getSellerUploadTemplateFileUnmaskBySeller', sellerId, gridState],
      async () => {
        return getSellerUploadTemplateFileUnmaskBySeller(
          sellerId,
          JSON.stringify(gridState)
        )
      },
      {
        enabled: !!sellerId,
        cacheTime: 0,
      }
    )

  const { data: portfolioTypes } = useCustomQuery<PortfolioType[]>(
    ['getAllPortfolioTypes'],
    async () => {
      return getAllPortfolioTypes()
    },
    {
      enabled: !!sellerId,
      cacheTime: 0,
    }
  )

  const { data: exportFileDefinitions, isLoading: isLoadingFileMaps } =
    useCustomQuery<ExportFileDefinition[]>(
      ['getFileDefinitionByPortfolioType', portfolioTypeId],
      async () => {
        return getFileDefinitionByPortfolioType(portfolioTypeId)
      },
      {
        enabled: !!portfolioTypeId,
        cacheTime: 0,
      }
    )

  const {
    data: exportFieldDefinitions,
    isFetching: fieldDefinitionsLoading,
    refetch: refechFields,
  } = useCustomQuery<ExportFieldDefinitionResponse>(
    [
      'getFieldsByFileDefinitionId',
      fileDefinitionId,
      validationManagerGridState,
      sellerId,
    ],
    async () => {
      return getFieldsByFileDefinitionId(
        fileDefinitionId,
        sellerId,
        JSON.stringify(validationManagerGridState)
      )
    },
    {
      enabled: !!fileDefinitionId,
      cacheTime: 0,
    }
  )

  const { isFetching: addOrRemoveFileUnmaskLoading } = useCustomQuery(
    ['addOrRemoveFileUnmaskWithColumns', unmaskWithColumnsRequest],
    async () => {
      if (unmaskWithColumnsRequest) {
        return addOrRemoveFileUnmaskWithColumns(unmaskWithColumnsRequest)
          .then(() => {
            enqueueSnackbar('Unmasked columns updated.', notifySuccess)
            setOpenEditModal(false)
            reactQueryClient.refetchQueries({
              queryKey: ['getSellerUploadTemplateFileUnmaskBySeller'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Error when update unmasked columns.', notifyError)
          })
          .finally(() => {
            setColumnsRequest([])
          })
      }
    },
    {
      enabled: !!unmaskWithColumnsRequest,
      cacheTime: 0,
    }
  )

  const { data: templateFileMap, isFetching: templateFileMapLoading } =
    useCustomQuery<PortfolioTemplateFileMapResponse>(
      ['getPortfolioTemplateFileMap', editTemplateId, openEditModal],
      async () => {
        return getPortfolioTemplateFileMap(editTemplateId)
      },
      {
        enabled: !!editTemplateId && openEditModal,
        cacheTime: 0,
      }
    )

  const handleSelectPortfolioType = (event: any) => {
    setPortfolioTypeId(event?.target?.value)
  }

  const handleSelectPageType = (event: any) => {
    setPageType(event?.target?.value)
  }

  const handleSelectFileDefinition = (event: any) => {
    setFileDefinitionId(event?.target?.value)
  }

  const saveCustomField = useMutation({
    mutationFn: (request: any) => updateFieldDefinition(request),
    onSuccess: (data: any) => {
      refechFields()
      setIsLoading(false)
      if (data) {
        enqueueSnackbar('Field customized successfully.', notifySuccess)
      } else {
        enqueueSnackbar('Field can not be customized.', notifySuccess)
      }
    },
    onError: () => {
      enqueueSnackbar('Error customizing field', notifyError)
      setIsLoading(false)
    },
  })

  const fieldForm = useFormik({
    initialValues,
    validationSchema: fieldSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setIsLoading(true)
      const fieldRequest = {
        id: editField.customFieldId || null,
        sellerId: sellerId,
        exportFieldDefinitionId: editField.id || null,
        exportFieldDefinitionName: values.exportFieldDefinitionName || null,
        exportFieldDescription: editField.exportFieldDescription || null,
        exportFieldType: editField.exportFieldType || null,
        exportFieldFormat: values.exportFieldFormat || null,
        isRequired: values.isRequired || null,
      }

      saveCustomField.mutate(fieldRequest)

      setSubmitting(false)
      resetForm()
      setOpenEditFieldModal(false)
    },
  })

  return (
    <Box>
      <Grid container direction="row" mb={5} style={{ gap: 10 }}>
        <Grid item>
          <TextField
            select
            label="Show"
            onChange={handleSelectPageType}
            value={pageType}
            style={{
              alignSelf: 'flex-start',
              width: '200px',
            }}
          >
            <MenuItem key={pageTypes.dataManager} value={pageTypes.dataManager}>
              Unmasked Data Manager
            </MenuItem>
            <MenuItem
              key={pageTypes.validationManager}
              value={pageTypes.validationManager}
            >
              Validation Manager
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>
      {pageType === pageTypes.dataManager && (
        <Box mb={6}>
          <Typography variant="h6">Portfolio Data Manager</Typography>
          <Grid mt={2} container spacing={4}>
            <Grid item xs={12} lg={12}>
              <DataTable
                isLoading={fileUnmaskDataLoading}
                isFetching={fileUnmaskDataLoading}
                gridColumns={gridColumns}
                gridState={gridState}
                data={fileUnmaskData?.sellerUploadTemplateFileUnmask}
                sortable
                pageable
                onRowClick={(row) => {
                  setEditTemplateId(row.dataItem.sellerUploadTemplateId)
                  setUnmaksEntireFile(
                    row.dataItem?.fileUnmask?.allColumnsSelected
                  )
                  setOpenEditModal(true)
                }}
                total={fileUnmaskData?.total}
                onDataStateChange={(e: any) => {
                  setGridState(e.dataState)
                }}
              />
            </Grid>
          </Grid>
          <ModalDialog
            isOpen={openEditModal}
            header="Edit Portfolio Data Manager"
            buttonOkText="Save"
            disableOkButton={
              columnsRequest.length === 0 &&
              unmaksEntireFile ===
                fileUnmaskData?.sellerUploadTemplateFileUnmask.find(
                  (x) => x.sellerUploadTemplateId === editTemplateId
                )?.fileUnmask?.allColumnsSelected
            }
            onClose={() => {
              setOpenEditModal(false)
              setColumnsRequest([])
            }}
            isFetching={addOrRemoveFileUnmaskLoading}
            isLoading={addOrRemoveFileUnmaskLoading}
            onContinue={handleSaveEditUnmask}
            width="80%"
          >
            <Box display="flex" flexDirection="column" gap={2}>
              {!templateFileMapLoading ? (
                <Box display="flex" width="100%" justifyContent="flex-end">
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={() => setUnmaksEntireFile(!unmaksEntireFile)}
                        name="unmaskEntireFile"
                        checked={unmaksEntireFile}
                        value={unmaksEntireFile}
                        disabled={!hasManageUnmaskedDataPermission}
                      />
                    }
                    label="Unmask the entire file"
                  />
                </Box>
              ) : (
                <Skeleton
                  style={{ marginTop: '-2px' }}
                  height={40}
                  width={150}
                />
              )}
              <DataTable
                isLoading={templateFileMapLoading}
                isFetching={templateFileMapLoading}
                gridColumns={gridColumnsEdit}
                gridState={gridStateEdit}
                data={templateFileMap?.columns}
                total={fileUnmaskData?.total}
              />
            </Box>
          </ModalDialog>
        </Box>
      )}
      {pageType === pageTypes.validationManager && (
        <Box mb={6}>
          <Typography variant="h6">Validation Manager</Typography>
          <Grid container direction="row" style={{ gap: 10 }}>
            <Grid item>
              <TextField
                select
                label="Portfolio Type"
                onChange={handleSelectPortfolioType}
                value={portfolioTypeId}
                style={{
                  alignSelf: 'flex-start',
                  width: '200px',
                }}
              >
                {portfolioTypes?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.typeName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              {isLoadingFileMaps && portfolioTypeId ? (
                <Skeleton
                  style={{ marginTop: '10px' }}
                  height={40}
                  width={150}
                ></Skeleton>
              ) : (
                <TextField
                  select
                  label="File Map"
                  onChange={handleSelectFileDefinition}
                  value={fileDefinitionId}
                  disabled={!portfolioTypeId}
                  style={{
                    alignSelf: 'flex-start',
                    width: '200px',
                  }}
                >
                  {exportFileDefinitions?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          </Grid>
          <Grid mt={2} container spacing={4}>
            <Grid item xs={12} lg={12}>
              <DataTable
                isLoading={fieldDefinitionsLoading}
                isFetching={fieldDefinitionsLoading}
                gridColumns={validationManagerGridColumns}
                gridState={validationManagerGridState}
                data={exportFieldDefinitions?.fields}
                useFilterMenu={true}
                sortable
                pageable
                total={exportFieldDefinitions?.total}
                onDataStateChange={(e: any) => {
                  setValidationManagerGridState(e.dataState)
                }}
                onRowClick={(row) => {
                  setEditField(row.dataItem)
                  setInitialValues({
                    exportFieldDefinitionName:
                      row.dataItem.exportFieldDefinitionName,
                    isRequired: row.dataItem.isRequired,
                    exportFieldFormat: row.dataItem.exportFieldFormat,
                  })
                  setOpenEditFieldModal(true)
                }}
              />
            </Grid>
          </Grid>
          <ModalDialog
            isOpen={openEditFieldModal}
            header="File Map Field"
            onClose={() => {
              setOpenEditFieldModal(false)
            }}
            disableOkButton={!fieldForm.isValid || !hasManageFileMapPermission}
            buttonOkText="Save"
            isForm={true}
            width="80%"
          >
            <form onSubmit={fieldForm.handleSubmit}>
              <Box>
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      label="Field Name"
                      name="exportFieldDefinitionName"
                      onChange={fieldForm.handleChange}
                      error={!!fieldForm.errors.exportFieldDefinitionName}
                      value={fieldForm.values.exportFieldDefinitionName}
                      helperText={fieldForm.errors.exportFieldDefinitionName}
                      disabled={true}
                    ></TextField>
                  </Grid>
                  {editField?.exportFieldType === 'Date' && (
                    <Grid item xs={12} lg={4}>
                      <InputLabel>Date Format</InputLabel>
                      <TextField
                        select
                        fullWidth
                        name="exportFieldFormat"
                        onChange={fieldForm.handleChange}
                        error={!!fieldForm.errors.exportFieldFormat}
                        value={fieldForm.values.exportFieldFormat}
                        helperText={fieldForm.errors.exportFieldFormat}
                        disabled={!hasManageFileMapPermission}
                      >
                        {dateFormats?.map((format) => (
                          <MenuItem key={format} value={format}>
                            {format.toUpperCase()}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                  <Grid item xs={12} lg={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          name="isRequired"
                          checked={fieldForm?.values.isRequired}
                          value={fieldForm?.values.isRequired}
                          onChange={fieldForm.handleChange}
                          disabled={
                            editField?.isMandatory ||
                            !hasManageFileMapPermission
                          }
                        />
                      }
                      label="Is Required"
                    />
                  </Grid>
                </Grid>
              </Box>
              <Flex
                data-test-id={'modal-dialog-footer'}
                justifyContent="flex-end"
                pb="12px"
                pt="20px"
              >
                <Button
                  data-test-id={'modal-dialog-ok-buton'}
                  margin={'0 0 0 10px'}
                  onClick={() => setOpenEditFieldModal(false)}
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button
                  data-test-id={'modal-dialog-ok-buton'}
                  margin={'0 0 0 10px'}
                  type="submit"
                  isFetching={isLoading}
                  disabled={!fieldForm.isValid || !hasManageFileMapPermission}
                >
                  Save
                </Button>
              </Flex>
            </form>
          </ModalDialog>
        </Box>
      )}
    </Box>
  )
}

export default FileUnmaskList
