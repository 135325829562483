import { httpClient } from '../../axios-wrapper/httpClient'
import * as urls from '../../urls/business'
import * as types from '../../models/business'

export const getBusinessFee = async (
  businessId: string | undefined
): Promise<types.BusinessFee[]> => {
  const response = await httpClient.get(urls.BusinessGetFee, {
    params: { businessId },
  })

  return response.data
}

export const addBusinessFee = async (
  request: types.AddBusinessFeeRequest
): Promise<void> => {
  await httpClient.post(urls.BusinessAddFee, request)
}

export const updateBusinessFee = async (
  request: types.UpdateBusinessFeeRequest
): Promise<void> => {
  await httpClient.post(urls.BusinessUpdateFee, request)
}

export const getBusinessFeeEnabled = async (
  businessId: string | undefined
): Promise<boolean> => {
  const response = await httpClient.get(urls.BusinessGetFeeEnabled, {
    params: { businessId },
  })

  return response.data
}

export const updateBusinessFeeEnabled = async (
  request: types.UpdateBusinessFeeEnabledRequest
): Promise<void> => {
  await httpClient.post(urls.BusinessSetFeeEnabled, request)
}

export const deleteBusinessFee = async (
  businessFeeId: number
): Promise<void> => {
  await httpClient.post(urls.BusinessDeleteFee, businessFeeId)
}

export const getBusinessFeatureFlag = async (
  businessId: string | undefined
): Promise<types.BusinessFeatureFlagResponse> => {
  const response = await httpClient.get(urls.BusinessGetFeatureFlag, {
    params: { businessId },
  })

  return response.data
}

export const getBusinessesWithClientAssoction = async (
  clientId: number | undefined,
  onlyBuyersSellers: boolean | undefined
): Promise<any> => {
  const response = await httpClient.get(urls.BusinessesWithClientAssoction, {
    params: {
      clientId,
      onlyBuyersSellers,
    },
  })
  return response?.data
}
