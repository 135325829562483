/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import { MenuItem, TextField } from '@mui/material'
import { State } from '@progress/kendo-data-query'
import React, { useState } from 'react'
import AuditInfo from 'src/components/Audit/AuditInfo'
import { AuditResponse } from 'src/infra/api/models/audit'
import { getCustomerChanges } from 'src/infra/api/services/audit'
import { useCustomQuery } from 'src/infra/react-query-wrapper'

interface ClientAuditInfoProps {
  id: string | number
  tableName: 'client' | 'business' | 'businessNotification' | undefined
}

const CustomerAuditInfo: React.FC<ClientAuditInfoProps> = ({
  id,
  tableName,
}) => {
  const [option, setOption] = useState(tableName)

  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const { data: clientAuditData, isFetching: loadingClientAudit } =
    useCustomQuery<AuditResponse>(
      ['getCustomerChanges', option],
      async () =>
        getCustomerChanges(id.toString(), option, JSON.stringify(gridState)),
      { enabled: option !== undefined, cacheTime: 0 }
    )

  return (
    <>
      {tableName === undefined && (
        <TextField
          data-cy="select-option"
          id="select-option"
          style={{ minWidth: 200, maxWidth: 500, marginBottom: 5 }}
          select
          label="Option"
          name="result"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e: any) => setOption(e.target.value)}
          value={option || null}
        >
          <MenuItem value="">
            <em>Select an option</em>
          </MenuItem>
          <MenuItem value="Business" key="business">
            Business
          </MenuItem>
          <MenuItem value="BusinessNotification" key="BusinessNotification">
            Notification
          </MenuItem>
        </TextField>
      )}
      <AuditInfo
        data={clientAuditData?.auditTableResponses}
        totalDataSize={clientAuditData?.total}
        isLoading={loadingClientAudit}
        gridState={gridState}
        onDataStateChange={(e) => setGridState(e.dataState)}
      />
    </>
  )
}

export default CustomerAuditInfo
