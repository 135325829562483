import React, { useContext, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Grid,
  Skeleton,
} from '@mui/material'
import { Add, Edit, SpeakerNotesOff } from '@mui/icons-material'

import { useQuery } from '@apollo/client'
import {
  GET_BANK_ACCOUNTS,
  GET_CLIENT,
} from 'src/graphql/operations/queries/clientProfiles'
import {
  GetBankAccounts,
  ClientBankAccountResponse,
  GetClient,
} from 'src/graphql/models/clientProfiles'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { ClientBankAccountsWrapper } from '../styles'
import ClientBankAccountForm from './ClientBankAccountForm'
import { Colors } from 'everchain-uilibrary'

interface ClientBankAccountsProps {
  clientProfileId: number
}

const ClientBankAccounts = ({ clientProfileId }: ClientBankAccountsProps) => {
  const ability = useContext(AbilityContext)
  const hasBasicInfoPermission = ability.can(
    PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION,
    'any'
  )
  const { data: getClientData } = useQuery<{
    getClient: GetClient
  }>(GET_CLIENT, { variables: { clientId: Number(clientProfileId) } })

  const countryCode =
    getClientData?.getClient.countryCode || process.env.REACT_APP_COUNTRY
  const [open, setOpen] = useState(false)
  const [isCreatingMode, setIsCreatingMode] = useState(false)
  const [bankAccountData, setBankAccountData] = useState<
    ClientBankAccountResponse | undefined
  >(undefined)

  const handlOpenToggle = () => {
    setOpen(!open)
  }

  const { data: getBankAccountsData, loading: banckAccountLoging } =
    useQuery<GetBankAccounts>(GET_BANK_ACCOUNTS, {
      variables: {
        request: {
          clientIdFilter: Number(clientProfileId),
        },
      },
      skip: !clientProfileId,
    })

  if (banckAccountLoging && !getBankAccountsData) {
    return (
      <Grid container spacing={4}>
        {[0, 1, 2].map((item) => (
          <Grid key={item} item xs={12} sm={6} md={4}>
            <Box height={200}>
              <Skeleton height={200} />
            </Box>
          </Grid>
        ))}
      </Grid>
    )
  }

  const getColorStatusBadge = (status: string) => {
    if (status === 'Active') return Colors.success
    if (status === 'Inactive') return Colors.error
    return ''
  }

  return (
    <>
      <ClientBankAccountsWrapper>
        <Box>
          <Typography variant="h6">
            Select a default bank account at the business level
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" my={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={!hasBasicInfoPermission}
            startIcon={<Add />}
            onClick={() => {
              handlOpenToggle()
              setIsCreatingMode(true)
            }}
          >
            Add Bank Account
          </Button>
        </Box>
        <Grid container spacing={4}>
          {getBankAccountsData?.getBankAccounts.length ? (
            getBankAccountsData?.getBankAccounts.map((bankAccount) => {
              return (
                <Grid key={bankAccount.id} item xs={12} sm={6} md={4}>
                  <Box className="banck-item">
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      bgcolor={Colors.mediumGray}
                      p={2.5}
                    >
                      <Box>
                        <Typography>{bankAccount.bankName}</Typography>
                        <Typography color="textSecondary">
                          {bankAccount.stateCode}
                        </Typography>
                      </Box>
                      <IconButton
                        onClick={() => {
                          setIsCreatingMode(false)
                          handlOpenToggle()
                          setBankAccountData(bankAccount)
                        }}
                        disabled={!hasBasicInfoPermission}
                      >
                        <Edit />
                      </IconButton>
                    </Box>
                    <Divider />
                    <Box p={2.5}>
                      {countryCode !== 'UK' && (
                        <Box pb={1}>
                          <Typography>Account Type</Typography>
                          <Typography color="textSecondary">
                            {bankAccount.accountTypeCode}
                          </Typography>
                        </Box>
                      )}
                      <Box pb={1}>
                        <Typography>Street</Typography>
                        <Typography color="textSecondary">
                          {bankAccount.street1 || '-'}
                        </Typography>
                      </Box>
                      <Box pb={1}>
                        <Typography>Bank Phone Number</Typography>
                        <Typography color="textSecondary">
                          {bankAccount.bankPhone}
                        </Typography>
                      </Box>
                      <Box pb={1}>
                        <Typography>Account No.</Typography>
                        <Typography color="textSecondary">
                          {bankAccount.accountNumber}
                        </Typography>
                      </Box>
                      <Box pb={1}>
                        <Typography>
                          {countryCode === 'UK' ? 'Sort Code' : 'Routing No.'}
                        </Typography>
                        <Typography color="textSecondary">
                          {bankAccount.routingNumber}
                        </Typography>
                      </Box>
                      <Box pb={1}>
                        <Typography>Name On Account</Typography>
                        <Typography color="textSecondary">
                          {bankAccount.nameOnAccount || '-'}
                        </Typography>
                      </Box>
                      <Box pb={1}>
                        <Typography>Status</Typography>
                        {bankAccount.status ? (
                          <Box
                            bgcolor={getColorStatusBadge(bankAccount.status)}
                            width={70}
                            sx={{ borderRadius: '16px' }}
                            display="flex"
                            justifyContent="center"
                            color={Colors.white}
                            pt={1}
                            pb={1}
                          >
                            {bankAccount.status}
                          </Box>
                        ) : (
                          <Typography color="textSecondary">'-'</Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              )
            })
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="100%"
              p={10}
            >
              <Box fontSize={28} color="text.secondary">
                <SpeakerNotesOff />
              </Box>
              <Typography>None</Typography>
            </Box>
          )}
        </Grid>
      </ClientBankAccountsWrapper>
      {open && (
        <ClientBankAccountForm
          open={open}
          onClose={handlOpenToggle}
          clientProfileId={clientProfileId}
          bankAccountData={!isCreatingMode ? bankAccountData : undefined}
          client={getClientData?.getClient}
        />
      )}
    </>
  )
}

export default ClientBankAccounts
