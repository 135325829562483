import { User, UserManager } from 'oidc-client'
import { ROOT } from 'src/routes'
import { IDENTITY_SETTINGS, authUrl, clientId } from './authConst'
import { USER_ID } from 'src/utils/constants'

const TENANT_TYPE = 'http://debttrader.com/claims/tenant_type'
export const IS_RESELLER = 'http://debttrader.com/claims/is_reseller'
export const CLIENT_INFO = 'http://debttrader.com/claims/client_info'
export const INTERNAL = 'internal'
export const EXTERNAL = 'external'
export const BUYER = 'Buyer'
export const SELLER = 'Seller'

export default class AuthService {
  userManager: UserManager

  constructor() {
    this.userManager = new UserManager(IDENTITY_SETTINGS)

    // Logger
    // Log.logger = console
    // Log.level = Log.DEBUG

    // If the user logout, he will be disconnect
    // from any tab that the app is open location
    this.userManager.events.addUserSignedOut(() => {
      this.logoutCallback()
    })
    // If the token cant be renewed, the session
    // expired and the user needs to log in again
    this.userManager.events.addSilentRenewError(() => {
      this.login()
    })

    this.userManager.events.addAccessTokenExpired(() => {})
  }

  // Methods
  getUser = async (): Promise<User> => {
    const user = await this.userManager.getUser()
    if (!user) {
      return this.userManager.signinRedirectCallback()
    }

    return user
  }

  login = () => {
    this.userManager.clearStaleState().then(() => {
      const url = localStorage.getItem('originationURI')
      window.localStorage.clear()
      if (url) {
        localStorage.setItem('originationURI', url)
      }
      this.userManager.signinRedirect()
    })
  }

  loginCallback = async (): Promise<void> => {
    return (
      this.userManager
        .signinRedirectCallback()
        .then((user) => {
          const url = localStorage.getItem('originationURI') || undefined
          window.localStorage.userId = user.profile[USER_ID]
          window.history.replaceState(
            {},
            window.document.title,
            window.location.origin + window.location.pathname
          )

          const profileTenantType = user.profile[TENANT_TYPE]

          window.localStorage.setItem('userType', profileTenantType)
          window.location.href = url || ROOT
        })
        // eslint-disable-next-line func-names
        .catch(function (erro) {
          // eslint-disable-next-line no-console
          console.log('error callback: ', erro)
        })
    )
  }

  isAuth = (): boolean => {
    const user = window.localStorage.getItem(
      `dt.user:${authUrl}:${clientId}` || ''
    )

    const userParser = user ? JSON.parse(user) : {}

    if (!!userParser && userParser.access_token) {
      return true
    }
    return false
  }

  logout = (): Promise<void> => {
    return this.userManager.signoutRedirect()
  }

  logoutCallback = (): Promise<void> => {
    return this.userManager.signoutRedirectCallback().then(() => {
      this.userManager.clearStaleState().then(() => {
        window.location.href = `${window.location.origin}/`
      })
    })
  }

  renewToken = async () => {
    const u = await this.userManager.signinSilent()

    return u
  }

  renewTokenCallback(): Promise<User | undefined> {
    return this.userManager.signinSilentCallback()
  }

  expiringAccessToken = (callback: any) => {
    this.userManager.events.addAccessTokenExpiring(() => {
      callback()
    })
  }

  expiredAccessToken = () => {
    this.userManager.events.addAccessTokenExpired(() => {
      this.logout()
    })
  }
}
