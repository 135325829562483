import React, { useEffect } from 'react'
import LoaderPage from 'src/components/LoaderPage'
import AuthService from 'src/configs/AuthService'

export const SignInCallback: React.FC = () => {
  useEffect(() => {
    const authService = new AuthService()
    authService.loginCallback()
  }, [])

  return <LoaderPage />
}
