/* eslint-disable no-param-reassign */
import VMasker from 'vanilla-masker'

interface MoneyOptions {
  // Decimal precision -> "90"
  precision?: number

  // Decimal separator -> ".90"
  separator?: string

  // Number delimiter -> "12,345,678"
  delimiter?: string

  // Money unit -> "$ 12,345,678.90"
  unit?: string

  // Money unit -> "12,345,678.90 $"
  suffixUnit?: string

  // Force type only number instead decimal,
  // masking decimals with ".00"
  // Zero cents -> "$ 1,234,567,890.00"
  zeroCents?: boolean
}

const moneyOptionDefault: MoneyOptions = {
  separator: '.',
  delimiter: ',',
  suffixUnit: '',
}

const FactoryVMasker = () => ({
  maskPercent: (value: any = '') => {
    return VMasker.toPattern(value, '99.99')
  },
  maskNumber: (value: any = '') => VMasker.toNumber(value),
  maskMoney: (value: any = '', options = moneyOptionDefault) =>
    VMasker.toMoney(value, options),
  maskerMoneyLocale: (
    value: any = '',
    country = 'US',
    options = moneyOptionDefault
  ) => {
    if (country === 'CA') options.unit = 'C$'
    if (country === 'UK') options.unit = '£'

    return VMasker.toMoney(value, options)
  },
  maskZipCode: (value: any, country: string | undefined) => {
    if (country?.toLowerCase() === 'us')
      return VMasker.toPattern(value, '99999-9999')
    if (country?.toLowerCase() === 'uk') {
      return VMasker.toPattern(value.toUpperCase(), 'SSSSSSS')
    }
    if (country?.toLowerCase() === 'ca') {
      return VMasker.toPattern(value.toUpperCase(), 'A9A 9A9')
    }
    if (country?.toLowerCase() === 'se')
      return VMasker.toPattern(value, '99999')

    return value
  },
  maskPhoneNumber: (value: string = '', country: any = '') => {
    if (country === 'UK') {
      if (!value) return ''
      if (!/^[+\d]/.test(value)) value = value.substring(1)
      if (value.length === 1) return value.replace(/[^\d+\s]/g, '')

      const firstCharacter = value[0]
      if (firstCharacter)
        return firstCharacter + value.substring(1).replace(/[^\d\s]/g, '')

      return value.replace(/[^\d\s]/g, '')
    }

    return VMasker.toPattern(value, '(999) 999-9999')
  },
  maskDate: (value: any = '') => VMasker.toPattern(value, '9999/99/99'),
  unmaskPhoneNumber: (value: string = '', country: any = '') => {
    if (country === 'UK') {
      return value
    }

    return value.replace(/[-() ]+/g, '')
  },
  unMaskMoney: (value: any = '') => value.replace(/[$£C$,]+/g, ''), // Removing dollar sign and comma
})

export const {
  maskNumber,
  maskPercent,
  maskMoney,
  maskerMoneyLocale,
  maskZipCode,
  maskPhoneNumber,
  unmaskPhoneNumber,
  maskDate,
  unMaskMoney,
} = FactoryVMasker()
