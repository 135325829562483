/* eslint-disable react/require-default-props */
import React, { useContext, useEffect, useState } from 'react'

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  CircularProgress,
  FormControlLabel,
  Switch,
  InputAdornment,
  IconButton,
  FormControl,
  RadioGroup,
  FormLabel,
  Radio,
  FormHelperText,
  Autocomplete,
  Skeleton,
} from '@mui/material'

import { Save, List as ListIcon } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { format, parse } from 'date-fns'

import { BusinessBuyerRequestType } from 'src/graphql/models/buyer'
import hardCodeData from 'src/utils/hardcodeData'
import {
  getCountryCurrency,
  getStateDropDownName,
  mapPropNullToString,
} from 'src/utils/common'
import { useAddOrUpdateBuyer } from 'src/graphql/operations/mutations/buyer'
import { useHistory } from 'react-router-dom'
import { BUYER_BUSINESS_MANAGE } from 'src/routes'
import { useSnackbar } from 'notistack'
import { BusinessNameHistory } from 'src/components/NameHistory'
import {
  CountryDataResponse,
  GetClient,
} from 'src/graphql/models/clientProfiles'
import {
  maskZipCode,
  maskPhoneNumber,
  maskMoney,
  unmaskPhoneNumber,
  unMaskMoney,
} from 'src/utils/masker'
import { useQuery } from '@apollo/client'
import { GET_COUNTRY } from 'src/graphql/operations/queries/clientProfiles'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  PermissionCodeAccess,
  TENANT_TYPE,
  WEEKDAYS,
} from 'src/utils/constants'
import BusinessInformationSkeleton from 'src/components/BusinessSkeleton'
import { AbilityContext } from 'src/context/Can'
import { getLocaleDateString } from 'src/utils/date'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import { Colors, DatePicker, ModalDialog } from 'everchain-uilibrary'
import { MobileTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { BusinessStatusInfo } from 'src/components/BusinessStatusInfo'

interface BuyerBusinessInformationProps {
  buyerBusinessId?: string | undefined
  clientId: number
  buyerData?: BusinessBuyerRequestType | undefined
  client?: GetClient
  buyerLoading?: boolean
  businessFeeEnabled?: boolean
}

const BuyerBusinessInformation = ({
  clientId,
  buyerBusinessId,
  buyerData,
  client,
  buyerLoading,
  businessFeeEnabled,
}: BuyerBusinessInformationProps) => {
  const initialDaysOfOperation = [
    WEEKDAYS[0],
    WEEKDAYS[1],
    WEEKDAYS[2],
    WEEKDAYS[3],
    WEEKDAYS[4],
  ]
  const { data: countryResponse, loading: loadingCountryData } =
    useQuery<CountryDataResponse>(GET_COUNTRY)
  const { user, profileClient } = useContext(AuthContext)
  const ability = useContext(AbilityContext)
  const hasBasicInfoPermission = ability.can(
    PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION,
    'any'
  )
  const hasInsurancePermission = ability.can(
    PermissionCodeAccess.CLIENT_BUSINESS_INSURANCE_PERMISSION,
    'any'
  )
  const hasStatusPermission = ability.can(
    PermissionCodeAccess.CLIENT_NONSELLER_STATUS_PERMISSION,
    'any'
  )
  const hasFeePermission = ability.can(
    PermissionCodeAccess.CLIENT_BUSINESS_FEES_PERMISSION,
    'any'
  )
  const hasScoringPermission = ability.can(
    PermissionCodeAccess.CLIENT_BUSINESS_SCORING_PERMISSION,
    'any'
  )

  const profileClientCountry =
    buyerData?.countryCode ||
    client?.countryCode ||
    process.env.REACT_APP_COUNTRY

  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'
  const country =
    countryResponse && countryResponse?.countryDataResponse[0].countryCode

  const showCountryOption =
    countryResponse && countryResponse?.countryDataResponse.length > 1

  const countryCode = client?.countryCode || process.env.REACT_APP_COUNTRY

  const validateZipCode = (zipCodeValue: string) => {
    if (BuyerBusinessForm.values.countryCode?.toLowerCase() === 'us') {
      const regex = /^[0-9]{5}(?:-[0-9]{4})?$/
      return regex.test(zipCodeValue || '')
    }
    if (BuyerBusinessForm.values.countryCode?.toLowerCase() === 'uk') {
      const regex = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/
      return regex.test(zipCodeValue || '')
    }
    if (BuyerBusinessForm.values.countryCode?.toLowerCase() === 'ca') {
      const regex = /^[A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d$/
      return regex.test(zipCodeValue || '')
    }
    if (BuyerBusinessForm.values.countryCode?.toLowerCase() === 'se') {
      const regex = /^[0-9]{5}$/
      return regex.test(zipCodeValue || '')
    }
    return false
  }

  const BuyerBusinessSchema = Yup.object().shape({
    name: Yup.string().nullable().required('Required'),
    status: Yup.string().nullable().required('Required'),
    feePercent: Yup.number().min(
      0,
      'Fee Percent must be more than or equal to 0'
    ),
    customerServiceEmail: Yup.string().nullable().email('Invalid email'),
    primaryContact_EMail: Yup.string().nullable().email('Invalid email'),
    postalCode: Yup.string().test(
      'zipCodeVaidation',
      'This is not a valid postal code',
      function (value) {
        return validateZipCode(value || '')
      }
    ),
    billingPostalCode: Yup.string().test(
      'zipCodeVaidation',
      'This is not a valid postal code',
      function (value) {
        return validateZipCode(value || '')
      }
    ),
    recertificationFrequency: Yup.number()
      .nullable()
      .min(1, 'Recertification frequency must be more than or equal to 1')
      .max(60, 'Recertification frequency must be less than or equal to 60'),
    startHoursOfOperation: Yup.string().nullable(),
    endHoursOfOperation: Yup.string()
      .nullable()
      .test(
        'endLaterStart',
        'End time must be greater than start time',
        // eslint-disable-next-line func-names
        function (endHoursOfOperation, context) {
          return context.parent.startHoursOfOperation
            ? parse(endHoursOfOperation || '', 'h:mm a', new Date()) >
                parse(
                  context.parent.startHoursOfOperation,
                  'h:mm a',
                  new Date()
                )
            : true
        }
      ),
    psaSignerName: Yup.string().when([], {
      is: () =>
        client?.eSignatureEnabledPSA ||
        BuyerBusinessForm.values.psaSignerTitle ||
        BuyerBusinessForm.values.psaSignerEmail,
      then: Yup.string().required('Required'),
    }),
    psaSignerTitle: Yup.string().when([], {
      is: () =>
        client?.eSignatureEnabledPSA ||
        BuyerBusinessForm.values.psaSignerName ||
        BuyerBusinessForm.values.psaSignerEmail,
      then: Yup.string().required('Required'),
    }),
    psaSignerEmail: Yup.string().when([], {
      is: () =>
        client?.eSignatureEnabledPSA ||
        BuyerBusinessForm.values.psaSignerName ||
        BuyerBusinessForm.values.psaSignerTitle,
      then: Yup.string().required('Required'),
    }),
  })

  const validate = (values: any) => {
    const errors: any = {}

    if (
      values.membershipEstablished &&
      !moment(
        values.membershipEstablished,
        formatInput.toUpperCase(),
        true
      ).isValid()
    ) {
      errors.membershipEstablished = 'Invalid date'
    }

    if (
      values.insurance_ExpirationDate &&
      !moment(
        values.insurance_ExpirationDate,
        formatInput.toUpperCase(),
        true
      ).isValid()
    ) {
      errors.insurance_ExpirationDate = 'Invalid date'
    }

    if (
      values.onSiteAudit &&
      !moment(values.onSiteAudit, formatInput.toUpperCase(), true).isValid()
    ) {
      errors.onSiteAudit = 'Invalid date'
    }

    return errors
  }

  const initialValueData = {
    businessType: 'buyer',
    status: 'Application In Process',
    legalName: '',
    name: '',
    street1: client?.street1 ?? '',
    street2: client?.street2 ?? '',
    city: client?.city ?? '',
    stateCode: client?.stateCode ?? '',
    postalCode: client?.postalCode ?? '',
    countryCode: client?.countryCode ?? country,
    corpHQPhoneNumber: client?.corpHQPhoneNumber,
    customerServicePhoneNumber: client?.customerServicePhoneNumber,
    customerServiceEmail: client?.customerServiceEmail,
    primaryContact_LastName: client?.primaryContact_LastName,
    primaryContact_FirstName: client?.primaryContact_FirstName,
    primaryContact_EMail: client?.primaryContact_EMail,
    primaryContact_OfficePhone: client?.primaryContact_OfficePhone,
    primaryContact_MobilePhone: client?.primaryContact_MobilePhone,
    importFromId: '',
    insurance_Name: '',
    insurance_PolicyType: '',
    insurance_CoverageAmount: 0,
    insurance_ExpirationDate: null,
    insurance_Comment: '',
    defaultBankAccountId: '',
    feePercent: 7,
    minimumFee: maskMoney('1,000.00'),
    enforceMinimumFee: true,
    dTCanPlaceBid: false,
    dTCanUploadDownloadPSA: false,
    dTCanConfirmFundsSent: false,
    dTCanCreatePostSaleRequest: false,
    dTCanRespondToPostSaleRequest: false,
    dTCanClosePostSaleRequest: false,
    dTCanMaintainComplaints: false,
    permissionsLastUpdatedByUserId: '',
    permissionsLastUpdatedUTC: '',
    signer_FullName: '',
    signer_Title: '',
    tU_ScoringEnabled: false,
    tU_UploadDirectory: '',
    tU_ProductType: 0,
    bankName: '',
    routingNumber: '',
    nameOnAccount: '',
    bankAccountStatus: '',
    bCOBuyerType: 'Passive',
    bCOAgenciesCertified: 0,
    bCOOfficerBackgroundChecks: 0,
    bCOAttestation: 0,
    bCOFinancials: 0,
    bCOComplianceNotes: '',
    bCOEnabled: false,
    membershipEstablished: null,
    onSiteAudit: null,
    recertificationFrequency: null,
    startHoursOfOperation: null,
    endHoursOfOperation: null,
    billingStreet1: client?.street1 ?? '',
    billingStreet2: client?.street2 ?? '',
    billingCity: client?.city ?? '',
    billingStateCode: client?.stateCode ?? '',
    billingPostalCode: client?.postalCode ?? '',
    psaSignerName: '',
    psaSignerTitle: '',
    psaSignerEmail: '',
    consumerPaymentInstruction: '',
    enablePartnershipScoring: false,
    companyWebsiteAddress: '',
    taxId: '',
    businessTypeLLC: '',
    stateOfIncorporation: '',
    dateOfEstablishment: null,
  }
  const [initialValues, setInitialValues] = useState<any>(initialValueData)
  const [openNameHistory, setOpenNameHistory] = useState(false)
  const loading = false
  const [daysOfOperation, setDaysOfOperation] = useState(initialDaysOfOperation)

  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()

  const formatInput = getLocaleDateString()

  const handleAddOrUpdateBuyerCompleted = (data: any) => {
    if (data) {
      const text = buyerBusinessId ? 'Update' : 'Create'
      enqueueSnackbar(`${text} buyer successful`, {
        variant: 'success',
      })
      if (data.addOrUpdateBuyer && data.addOrUpdateBuyer.id) {
        history.push(
          `${BUYER_BUSINESS_MANAGE}/${clientId}/${data.addOrUpdateBuyer.id}`
        )
      }
    }
  }

  const { addOrUpdateBuyer, loading: addOrUpdateBuyerLoading } =
    useAddOrUpdateBuyer(handleAddOrUpdateBuyerCompleted)

  const BuyerBusinessForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: BuyerBusinessSchema,
    validate,

    onSubmit: (values, { setSubmitting }) => {
      const buyerRequest = {
        businessType: 'buyer',
        status: values.status || null,
        legalName: values.legalName || null,
        name: values.name || null,
        street1: values.street1 || null,
        street2: values.street2 || null,
        city: values.city || null,
        stateCode: values.stateCode || null,
        postalCode: values.postalCode || null,
        countryCode: values.countryCode || '',
        customerServiceEmail: values.customerServiceEmail || null,
        primaryContact_LastName: values.primaryContact_LastName || null,
        primaryContact_FirstName: values.primaryContact_FirstName || null,
        primaryContact_EMail: values.primaryContact_EMail || null,
        corpHQPhoneNumber: values.corpHQPhoneNumber
          ? unmaskPhoneNumber(values.corpHQPhoneNumber, countryCode)
          : null,
        customerServicePhoneNumber: values.customerServicePhoneNumber
          ? unmaskPhoneNumber(values.customerServicePhoneNumber, countryCode)
          : null,
        primaryContact_OfficePhone: values.primaryContact_OfficePhone
          ? unmaskPhoneNumber(values.primaryContact_OfficePhone, countryCode)
          : null,
        primaryContact_MobilePhone: values.primaryContact_MobilePhone
          ? unmaskPhoneNumber(values.primaryContact_MobilePhone, countryCode)
          : null,
        importFromId: values.importFromId || null,
        insurance_Name: values.insurance_Name || null,
        insurance_PolicyType: values.insurance_PolicyType || null,
        insurance_CoverageAmount: values.insurance_CoverageAmount
          ? Number(unMaskMoney(values.insurance_CoverageAmount))
          : null,
        insurance_ExpirationDate: values.insurance_ExpirationDate
          ? new Date(values.insurance_ExpirationDate)
          : null,
        insurance_Comment: values.insurance_Comment || null,
        defaultBankAccountId: values.defaultBankAccountId || null,
        feePercent:
          values.feePercent || values.feePercent === 0
            ? values.feePercent
            : null,
        minimumFee: values.minimumFee
          ? Number(unMaskMoney(values.minimumFee))
          : null,
        enforceMinimumFee: values.enforceMinimumFee,
        dTCanPlaceBid: values.dTCanPlaceBid,
        dTCanUploadDownloadPSA: values.dTCanUploadDownloadPSA,
        dTCanConfirmFundsSent: values.dTCanConfirmFundsSent,
        dTCanCreatePostSaleRequest: values.dTCanCreatePostSaleRequest,
        dTCanRespondToPostSaleRequest: values.dTCanRespondToPostSaleRequest,
        dTCanClosePostSaleRequest: values.dTCanClosePostSaleRequest,
        dTCanMaintainComplaints: values.dTCanMaintainComplaints,
        permissionsLastUpdatedByUserId:
          values.permissionsLastUpdatedByUserId || null,
        permissionsLastUpdatedUTC: values.permissionsLastUpdatedUTC || null,
        signer_FullName: values.signer_FullName || null,
        signer_Title: values.signer_Title || null,
        tU_ScoringEnabled: values.tU_ScoringEnabled,
        tU_UploadDirectory: values.tU_UploadDirectory || null,
        tU_ProductType: values.tU_ProductType || 0,
        bankName: values.bankName || null,
        routingNumber: values.routingNumber || null,
        nameOnAccount: values.nameOnAccount || null,
        bankAccountStatus: values.bankAccountStatus || null,
        bCOBuyerType: values.bCOBuyerType,
        bCOAgenciesCertified: values.bCOAgenciesCertified,
        bCOOfficerBackgroundChecks: values.bCOOfficerBackgroundChecks,
        bCOAttestation: values.bCOAttestation,
        bCOFinancials: values.bCOFinancials,
        bCOComplianceNotes: values.bCOComplianceNotes || null,
        bCOEnabled: values.bCOEnabled,
        membershipEstablished: values.membershipEstablished
          ? new Date(values.membershipEstablished)
          : null,
        dateOfEstablishment: values.dateOfEstablishment
          ? new Date(values.dateOfEstablishment)
          : null,
        companyWebsiteAddress: values.companyWebsiteAddress || null,
        businessTypeLLC: values.businessTypeLLC || null,
        taxId: values.taxId || null,
        stateOfIncorporation: values.stateOfIncorporation || null,
        onSiteAudit: values.onSiteAudit ? new Date(values.onSiteAudit) : null,
        recertificationFrequency: values.recertificationFrequency || null,
        startHoursOfOperation: values?.startHoursOfOperation,
        endHoursOfOperation: values?.endHoursOfOperation,
        daysOfOperation: daysOfOperation.map((x) => x.value),
        billingStreet1: values.billingStreet1 || null,
        billingStreet2: values.billingStreet2 || null,
        billingCity: values.billingCity || null,
        billingStateCode: values.billingStateCode || null,
        billingPostalCode: values.billingPostalCode || null,
        psaSignerName: values.psaSignerName || null,
        psaSignerTitle: values.psaSignerTitle || null,
        psaSignerEmail: values.psaSignerEmail || null,
        consumerPaymentInstruction: values.consumerPaymentInstruction || null,
        enablePartnershipScoring: values?.enablePartnershipScoring || false,
      }
      if (buyerBusinessId) {
        addOrUpdateBuyer({
          variables: {
            buyer: {
              id: buyerBusinessId,
              clientId: Number(clientId),
              ...buyerRequest,
            },
          },
          refetchQueries: ['GetBuyer', 'GetBuyerBcoData'],
        })
      } else {
        addOrUpdateBuyer({
          variables: {
            buyer: {
              clientId: Number(clientId),
              ...buyerRequest,
            },
          },
          refetchQueries: ['GetBuyer', 'GetBuyerBcoData'],
        })
      }
    },
  })

  const onChangeTime = (date: any, field: string) => {
    BuyerBusinessForm.setFieldValue(field, format(new Date(date), 'hh:mm a'))
  }

  const handleNameHistoryToogle = () => {
    setOpenNameHistory(!openNameHistory)
  }

  const handleCountryChange = (value: any, name: any) => {
    BuyerBusinessForm.setFieldValue('stateCode', null, true)
    BuyerBusinessForm.setFieldValue('billingStateCode', null, true)
    BuyerBusinessForm.setFieldValue('postalCode', '', true)
    BuyerBusinessForm.setFieldValue('billingPostalCode', '', true)
    BuyerBusinessForm.setFieldValue(name, value, true)
  }

  useEffect(() => {
    if (buyerData) {
      setInitialValues((prevState: BusinessBuyerRequestType) => ({
        ...prevState,
        ...mapPropNullToString(buyerData),
        enablePartnershipScoring: buyerData.enablePartnershipScoring,
        minimumFee: buyerData.minimumFee
          ? maskMoney(parseFloat(buyerData.minimumFee.toString()).toFixed(2))
          : buyerData.minimumFee,
        corpHQPhoneNumber: buyerData.corpHQPhoneNumber
          ? maskPhoneNumber(buyerData.corpHQPhoneNumber, countryCode)
          : null,
        customerServicePhoneNumber: buyerData.customerServicePhoneNumber
          ? maskPhoneNumber(buyerData.customerServicePhoneNumber, countryCode)
          : null,
        primaryContact_OfficePhone: buyerData.primaryContact_OfficePhone
          ? maskPhoneNumber(buyerData.primaryContact_OfficePhone, countryCode)
          : null,
        primaryContact_MobilePhone: buyerData.primaryContact_MobilePhone
          ? maskPhoneNumber(buyerData.primaryContact_MobilePhone, countryCode)
          : null,
        insurance_CoverageAmount: buyerData.insurance_CoverageAmount
          ? maskMoney(
              parseFloat(buyerData.insurance_CoverageAmount.toString()).toFixed(
                2
              )
            )
          : null,
        feePercent:
          isNaN(buyerData.feePercent) || buyerData.feePercent === null
            ? null
            : Number(parseFloat(buyerData.feePercent.toString()).toFixed(2)),
        onSiteAudit: buyerData.onSiteAudit
          ? new Date(buyerData.onSiteAudit)
          : null,
        insurance_ExpirationDate: buyerData.insurance_ExpirationDate
          ? new Date(buyerData.insurance_ExpirationDate)
          : null,
        membershipEstablished: buyerData.membershipEstablished
          ? new Date(buyerData.membershipEstablished)
          : null,
        dateOfEstablishment: buyerData.dateOfEstablishment
          ? new Date(buyerData.dateOfEstablishment)
          : null,
      }))
      setDaysOfOperation(
        buyerData.daysOfOperation.length > 0
          ? WEEKDAYS.filter(
              (x) => buyerData.daysOfOperation.indexOf(x.value) !== -1
            )
          : initialDaysOfOperation
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyerData, countryCode])

  if (buyerLoading) {
    return <BusinessInformationSkeleton />
  }

  return (
    <>
      <form onSubmit={BuyerBusinessForm.handleSubmit}>
        <Box mb={12}>
          <Typography variant="h6">Buyer Business Info</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.name}
                value={BuyerBusinessForm.values.name || ''}
                helperText={<>{BuyerBusinessForm.errors.name}</>}
                InputProps={{
                  endAdornment: buyerBusinessId && (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleNameHistoryToogle()
                        }}
                      >
                        <ListIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4} display="flex" flexDirection="row">
              <TextField
                fullWidth
                label="Status"
                select
                disabled={!isInternal || !hasStatusPermission}
                name="status"
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.status}
                value={BuyerBusinessForm.values.status || ''}
                helperText={<>{BuyerBusinessForm.errors.status}</>}
              >
                {hardCodeData
                  .getBusinessStatus(BuyerBusinessForm?.values.businessType)
                  .map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </TextField>
              <BusinessStatusInfo
                businessType={BuyerBusinessForm?.values.businessType}
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl component="fieldset">
                <FormLabel
                  style={{ fontSize: '0.75rem', fontFamily: 'inherit' }}
                >
                  Buyer Type
                </FormLabel>
                <RadioGroup
                  aria-label="bCOBuyerType"
                  name="bCOBuyerType"
                  value={BuyerBusinessForm.values.bCOBuyerType}
                  onChange={BuyerBusinessForm.handleChange}
                  row
                >
                  <FormControlLabel
                    value="Active"
                    control={
                      <Radio
                        disabled={!hasBasicInfoPermission}
                        color="primary"
                      />
                    }
                    label="Active"
                  />
                  <FormControlLabel
                    value="Passive"
                    control={
                      <Radio
                        disabled={!hasBasicInfoPermission}
                        color="primary"
                      />
                    }
                    label="Passive"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Legal Name"
                name="legalName"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.legalName}
                value={BuyerBusinessForm.values.legalName || ''}
                helperText={<>{BuyerBusinessForm.errors.legalName}</>}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={!isInternal || !hasBasicInfoPermission}
                fullWidth
                data-cy="dialog-input-field-companyWebsiteAddress"
                label="Company Website Address"
                name="companyWebsiteAddress"
                onChange={BuyerBusinessForm.handleChange}
                value={BuyerBusinessForm.values.companyWebsiteAddress || ''}
                error={!!BuyerBusinessForm.errors.companyWebsiteAddress}
                helperText={
                  <>{BuyerBusinessForm.errors.companyWebsiteAddress}</>
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                disabled={!isInternal || !hasBasicInfoPermission}
                fullWidth
                data-cy="dialog-input-field-stateofIncorporation"
                label="State of Incorporation"
                name="stateOfIncorporation"
                onChange={BuyerBusinessForm.handleChange}
                value={BuyerBusinessForm.values.stateOfIncorporation || ''}
                error={!!BuyerBusinessForm.errors.stateOfIncorporation}
                helperText={
                  <>{BuyerBusinessForm.errors.stateOfIncorporation}</>
                }
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                disabled={!isInternal || !hasBasicInfoPermission}
                fullWidth
                data-cy="dialog-input-field-taxID"
                label={'Tax ID #'}
                name="taxId"
                inputProps={{
                  maxLength: 20,
                }}
                onChange={BuyerBusinessForm.handleChange}
                value={BuyerBusinessForm.values.taxId || ''}
                error={!!BuyerBusinessForm.errors.taxId}
                helperText={<>{BuyerBusinessForm.errors.taxId}</>}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                disabled={!isInternal || !hasBasicInfoPermission}
                fullWidth
                data-cy="dialog-input-field-businessType"
                label={'Business Type (LLC)'}
                name="businessTypeLLC"
                onChange={BuyerBusinessForm.handleChange}
                value={BuyerBusinessForm.values.businessTypeLLC || ''}
                error={!!BuyerBusinessForm.errors.businessTypeLLC}
                helperText={<>{BuyerBusinessForm.errors.businessTypeLLC}</>}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <DatePicker
                label="Date of Establishment"
                disabled={!isInternal || !hasBasicInfoPermission}
                name="dateOfEstablishment"
                onChange={(date) => {
                  BuyerBusinessForm.setFieldValue(
                    'dateOfEstablishment',
                    date,
                    false
                  )
                }}
                value={BuyerBusinessForm.values.dateOfEstablishment}
                errorMessage={BuyerBusinessForm.errors.dateOfEstablishment?.toString()}
                country={profileClient?.Country}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    disabled={!isInternal}
                    onChange={BuyerBusinessForm.handleChange}
                    name="enablePartnershipScoring"
                    value={BuyerBusinessForm.values.enablePartnershipScoring}
                    checked={BuyerBusinessForm.values.enablePartnershipScoring}
                  />
                }
                label="Enable partnership scoring"
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Hours of Operation (PST)</Typography>
          <Grid
            container
            spacing={8}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Grid item xs={3} lg={3}>
              <MobileTimePicker
                label="Start"
                disabled={!isInternal || !hasBasicInfoPermission}
                value={
                  BuyerBusinessForm.values.startHoursOfOperation
                    ? dayjs(
                        parse(
                          BuyerBusinessForm.values.endHoursOfOperation,
                          'h:mm a',
                          new Date()
                        )
                      )
                    : BuyerBusinessForm.values.startHoursOfOperation
                }
                onChange={(date) => onChangeTime(date, 'startHoursOfOperation')}
                ampm
                minutesStep={30}
                orientation="landscape"
              />
            </Grid>
            <Grid item xs={3} lg={3}>
              <MobileTimePicker
                label="End"
                disabled={!isInternal || !hasBasicInfoPermission}
                value={
                  BuyerBusinessForm.values.endHoursOfOperation
                    ? dayjs(
                        parse(
                          BuyerBusinessForm.values.endHoursOfOperation,
                          'h:mm a',
                          new Date()
                        )
                      )
                    : BuyerBusinessForm.values.endHoursOfOperation
                }
                onChange={(date) => onChangeTime(date, 'endHoursOfOperation')}
                ampm
                minutesStep={30}
                orientation="landscape"
              />
              {BuyerBusinessForm.errors.endHoursOfOperation && (
                <FormHelperText style={{ color: Colors.error }}>
                  <>{BuyerBusinessForm.errors.endHoursOfOperation}</>
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6} lg={6}>
              <Autocomplete
                id="daysOfOperation"
                multiple
                options={WEEKDAYS}
                disabled={!isInternal || !hasBasicInfoPermission}
                getOptionLabel={(option) => option.day}
                value={daysOfOperation}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={!hasBasicInfoPermission}
                    variant="standard"
                    name="daysOfOperation"
                    label="Days of the Week"
                    placeholder="Chose one or more days of the week"
                    error={!!BuyerBusinessForm.errors.daysOfOperation}
                  />
                )}
                onChange={(ev, values) => {
                  values && values.length > 0
                    ? setDaysOfOperation(values)
                    : enqueueSnackbar(
                        'You need to select at least one weekday',
                        {
                          variant: 'error',
                        }
                      )
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Address</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Street"
                name="street1"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.street1}
                value={BuyerBusinessForm.values.street1 || ''}
                helperText={<>{BuyerBusinessForm.errors.street1}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Street 2"
                name="street2"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.street2}
                value={BuyerBusinessForm.values.street2 || ''}
                helperText={<>{BuyerBusinessForm.errors.street2}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="City"
                name="city"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.city}
                value={BuyerBusinessForm.values.city || ''}
                helperText={<>{BuyerBusinessForm.errors.city}</>}
              />
            </Grid>
            {showCountryOption && (
              <Grid item xs={12} lg={4}>
                {loadingCountryData ? (
                  <Skeleton variant="rectangular" width="100%" />
                ) : (
                  <TextField
                    fullWidth
                    label="Country"
                    name="countryCode"
                    select
                    disabled={!isInternal || !hasBasicInfoPermission}
                    onChange={({ target: { value, name } }) => {
                      handleCountryChange(value, name)
                    }}
                    error={!!BuyerBusinessForm.errors.countryCode}
                    value={BuyerBusinessForm.values.countryCode || ''}
                    helperText={<>{BuyerBusinessForm.errors.countryCode}</>}
                  >
                    {countryResponse?.countryDataResponse.map((option) => (
                      <MenuItem
                        key={option.countryCode}
                        value={option.countryCode}
                      >
                        {option.countryDescription}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Grid>
            )}
            <Grid item xs={12} lg={4}>
              {loadingCountryData ? (
                <Skeleton variant="rectangular" width="100%" />
              ) : (
                <TextField
                  fullWidth
                  label={getStateDropDownName(country)}
                  name="stateCode"
                  select
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={BuyerBusinessForm.handleChange}
                  error={!!BuyerBusinessForm.errors.stateCode}
                  value={BuyerBusinessForm.values.stateCode || ''}
                  helperText={<>{BuyerBusinessForm.errors.stateCode}</>}
                >
                  {countryResponse?.countryDataResponse
                    .find(
                      (x) =>
                        x.countryCode === BuyerBusinessForm.values.countryCode
                    )
                    ?.stateInfo.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.description}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Postal Code"
                name="postalCode"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={({ target: { value, name } }) => {
                  const masked = maskZipCode(
                    value,
                    BuyerBusinessForm.values.countryCode
                  )
                  BuyerBusinessForm.setFieldValue(name, masked, true)
                }}
                error={!!BuyerBusinessForm.errors.postalCode}
                value={BuyerBusinessForm.values.postalCode || ''}
                helperText={<>{BuyerBusinessForm.errors.postalCode}</>}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Billing Address</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Street"
                name="billingStreet1"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.billingStreet1}
                value={BuyerBusinessForm.values.billingStreet1 || ''}
                helperText={<>{BuyerBusinessForm.errors.billingStreet1}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Street 2"
                name="billingStreet2"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.billingStreet2}
                value={BuyerBusinessForm.values.billingStreet2 || ''}
                helperText={<>{BuyerBusinessForm.errors.billingStreet2}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="City"
                name="billingCity"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.billingCity}
                value={BuyerBusinessForm.values.billingCity || ''}
                helperText={<>{BuyerBusinessForm.errors.billingCity}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              {loadingCountryData ? (
                <Skeleton variant="rectangular" width="100%" />
              ) : (
                <TextField
                  fullWidth
                  label={getStateDropDownName(country)}
                  name="billingStateCode"
                  select
                  disabled={!isInternal || !hasBasicInfoPermission}
                  onChange={BuyerBusinessForm.handleChange}
                  error={!!BuyerBusinessForm.errors.billingStateCode}
                  value={BuyerBusinessForm.values.billingStateCode || ''}
                  helperText={<>{BuyerBusinessForm.errors.billingStateCode}</>}
                >
                  {countryResponse?.countryDataResponse
                    .find(
                      (x) =>
                        x.countryCode === BuyerBusinessForm.values.countryCode
                    )
                    ?.stateInfo.map((option) => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.description}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Postal Code"
                name="billingPostalCode"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={({ target: { value, name } }) => {
                  const masked = maskZipCode(
                    value,
                    BuyerBusinessForm.values.countryCode
                  )
                  BuyerBusinessForm.setFieldValue(name, masked, true)
                }}
                error={!!BuyerBusinessForm.errors.billingPostalCode}
                value={BuyerBusinessForm.values.billingPostalCode || ''}
                helperText={<>{BuyerBusinessForm.errors.billingPostalCode}</>}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Buyer Contact Information</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="HQ Phone"
                name="corpHQPhoneNumber"
                disabled={!isInternal || !hasBasicInfoPermission}
                inputProps={{ maxlength: 20 }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, countryCode)
                  BuyerBusinessForm.setFieldValue(name, masked, false)
                }}
                error={!!BuyerBusinessForm.errors.corpHQPhoneNumber}
                value={BuyerBusinessForm.values.corpHQPhoneNumber || ''}
                helperText={<>{BuyerBusinessForm.errors.corpHQPhoneNumber}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Customer Services Phone"
                name="customerServicePhoneNumber"
                disabled={!isInternal || !hasBasicInfoPermission}
                inputProps={{ maxlength: 20 }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, countryCode)
                  BuyerBusinessForm.setFieldValue(name, masked, false)
                }}
                error={!!BuyerBusinessForm.errors.customerServicePhoneNumber}
                value={
                  BuyerBusinessForm.values.customerServicePhoneNumber || ''
                }
                helperText={
                  <>{BuyerBusinessForm.errors.customerServicePhoneNumber}</>
                }
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Customer Services E-mail"
                name="customerServiceEmail"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.customerServiceEmail}
                value={BuyerBusinessForm.values.customerServiceEmail || ''}
                helperText={
                  <>{BuyerBusinessForm.errors.customerServiceEmail}</>
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Primary Contact</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Last Name"
                name="primaryContact_LastName"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.primaryContact_LastName}
                value={BuyerBusinessForm.values.primaryContact_LastName || ''}
                helperText={
                  <>{BuyerBusinessForm.errors.primaryContact_LastName}</>
                }
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="First Name"
                name="primaryContact_FirstName"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.primaryContact_FirstName}
                value={BuyerBusinessForm.values.primaryContact_FirstName || ''}
                helperText={
                  <>{BuyerBusinessForm.errors.primaryContact_FirstName}</>
                }
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="E-mail"
                name="primaryContact_EMail"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.primaryContact_EMail}
                value={BuyerBusinessForm.values.primaryContact_EMail || ''}
                helperText={
                  <>{BuyerBusinessForm.errors.primaryContact_EMail}</>
                }
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Direct (Phone)"
                name="primaryContact_OfficePhone"
                disabled={!isInternal || !hasBasicInfoPermission}
                inputProps={{ maxlength: 20 }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, countryCode)
                  BuyerBusinessForm.setFieldValue(name, masked, false)
                }}
                error={!!BuyerBusinessForm.errors.primaryContact_OfficePhone}
                value={
                  BuyerBusinessForm.values.primaryContact_OfficePhone || ''
                }
                helperText={
                  <>{BuyerBusinessForm.errors.primaryContact_OfficePhone}</>
                }
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Mobile"
                name="primaryContact_MobilePhone"
                disabled={!isInternal || !hasBasicInfoPermission}
                inputProps={{ maxlength: 20 }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskPhoneNumber(value, countryCode)
                  BuyerBusinessForm.setFieldValue(name, masked, false)
                }}
                error={!!BuyerBusinessForm.errors.primaryContact_MobilePhone}
                value={
                  BuyerBusinessForm.values.primaryContact_MobilePhone || ''
                }
                helperText={
                  <>{BuyerBusinessForm.errors.primaryContact_MobilePhone}</>
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={12}>
          <Typography variant="h6">Signer</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Full Name"
                name="signer_FullName"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.signer_FullName}
                value={BuyerBusinessForm.values.signer_FullName || ''}
                helperText={<>{BuyerBusinessForm.errors.signer_FullName}</>}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                fullWidth
                label="Title"
                name="signer_Title"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.signer_Title}
                value={BuyerBusinessForm.values.signer_Title || ''}
                helperText={<>{BuyerBusinessForm.errors.signer_Title}</>}
              />
            </Grid>
          </Grid>
        </Box>
        {isInternal && (
          <>
            <Box mb={12}>
              <Typography variant="h6">Fees</Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    label="Fee Percent"
                    name="feePercent"
                    type="number"
                    disabled={
                      !isInternal || !hasFeePermission || businessFeeEnabled
                    }
                    onChange={BuyerBusinessForm.handleChange}
                    error={!!BuyerBusinessForm.errors.feePercent}
                    value={BuyerBusinessForm.values.feePercent || ''}
                    helperText={<>{BuyerBusinessForm.errors.feePercent}</>}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        onChange={BuyerBusinessForm.handleChange}
                        name="enforceMinimumFee"
                        disabled={
                          !isInternal || !hasFeePermission || businessFeeEnabled
                        }
                        checked={BuyerBusinessForm.values.enforceMinimumFee}
                        value={BuyerBusinessForm.values.enforceMinimumFee}
                      />
                    }
                    label="Enforce Minimum Fee"
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    label="Minimum Fee"
                    name="minimumFee"
                    disabled={
                      !isInternal || !hasFeePermission || businessFeeEnabled
                    }
                    onChange={({ target: { value, name } }) => {
                      const masked = maskMoney(value)
                      BuyerBusinessForm.setFieldValue(name, masked, false)
                    }}
                    error={!!BuyerBusinessForm.errors.minimumFee}
                    value={BuyerBusinessForm.values.minimumFee || ''}
                    helperText={<>{BuyerBusinessForm.errors.minimumFee}</>}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {' '}
                          {getCountryCurrency(profileClientCountry)}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mb={12}>
              <Typography variant="h6">Insurance</Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="insurance_Name"
                    disabled={!isInternal || !hasInsurancePermission}
                    onChange={BuyerBusinessForm.handleChange}
                    error={!!BuyerBusinessForm.errors.insurance_Name}
                    value={BuyerBusinessForm.values.insurance_Name || ''}
                    helperText={<>{BuyerBusinessForm.errors.insurance_Name}</>}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    label="Policy Type"
                    name="insurance_PolicyType"
                    disabled={!isInternal || !hasInsurancePermission}
                    onChange={BuyerBusinessForm.handleChange}
                    error={!!BuyerBusinessForm.errors.insurance_PolicyType}
                    value={BuyerBusinessForm.values.insurance_PolicyType || ''}
                    helperText={
                      <>{BuyerBusinessForm.errors.insurance_PolicyType}</>
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    label="Coverage Amount"
                    disabled={!isInternal || !hasInsurancePermission}
                    name="insurance_CoverageAmount"
                    onChange={({ target: { value, name } }) => {
                      const masked = maskMoney(value)
                      BuyerBusinessForm.setFieldValue(name, masked, false)
                    }}
                    error={!!BuyerBusinessForm.errors.insurance_CoverageAmount}
                    value={
                      BuyerBusinessForm.values.insurance_CoverageAmount || ''
                    }
                    helperText={
                      <>{BuyerBusinessForm.errors.insurance_CoverageAmount}</>
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {' '}
                          {getCountryCurrency(profileClientCountry)}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <DatePicker
                    name="insurance_ExpirationDate"
                    id="insurance_ExpirationDate"
                    label="Expiration Date"
                    disabled={!isInternal || !hasInsurancePermission}
                    value={
                      BuyerBusinessForm.values.insurance_ExpirationDate || ''
                    }
                    errorMessage={BuyerBusinessForm.errors.insurance_ExpirationDate?.toString()}
                    onChange={(date) => {
                      BuyerBusinessForm.setFieldValue(
                        'insurance_ExpirationDate',
                        date,
                        false
                      )
                    }}
                    country={profileClient?.Country}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    label="Comment"
                    name="insurance_Comment"
                    disabled={!isInternal || !hasInsurancePermission}
                    onChange={BuyerBusinessForm.handleChange}
                    error={!!BuyerBusinessForm.errors.insurance_Comment}
                    value={BuyerBusinessForm.values.insurance_Comment || ''}
                    helperText={
                      <>{BuyerBusinessForm.errors.insurance_Comment}</>
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            {BuyerBusinessForm.values.countryCode !== 'UK' && ( // hide scoring component for UK business
              <Box mb={12}>
                <Typography variant="h6">TransUnion Scoring</Typography>
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={4}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          disabled={!isInternal || !hasScoringPermission}
                          onChange={BuyerBusinessForm.handleChange}
                          name="tU_ScoringEnabled"
                          value={BuyerBusinessForm.values.tU_ScoringEnabled}
                          checked={BuyerBusinessForm.values.tU_ScoringEnabled}
                        />
                      }
                      label="Enable TransUnion Scoring"
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      label="Upload Directory"
                      name="tU_UploadDirectory"
                      disabled={!isInternal || !hasScoringPermission}
                      onChange={BuyerBusinessForm.handleChange}
                      error={!!BuyerBusinessForm.errors.tU_UploadDirectory}
                      value={BuyerBusinessForm.values.tU_UploadDirectory || ''}
                      helperText={
                        <>{BuyerBusinessForm.errors.tU_UploadDirectory}</>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      fullWidth
                      label="TransUnion File Spec"
                      select
                      name="tU_ProductType"
                      disabled={!isInternal || !hasScoringPermission}
                      onChange={BuyerBusinessForm.handleChange}
                      value={BuyerBusinessForm.values.tU_ProductType}
                    >
                      <MenuItem value={0}>
                        Collection Prioritization (legacy)
                      </MenuItem>
                      <MenuItem value={1}>
                        Portfolio Valuation Solution (newer)
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box mb={12}>
              <Typography variant="h6">Compliance</Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    type="number"
                    label="Recertification Frequency (Months)"
                    name="recertificationFrequency"
                    disabled={!isInternal && !hasBasicInfoPermission}
                    onChange={BuyerBusinessForm.handleChange}
                    error={!!BuyerBusinessForm.errors.recertificationFrequency}
                    value={
                      BuyerBusinessForm.values.recertificationFrequency || ''
                    }
                    helperText={
                      <>{BuyerBusinessForm.errors.recertificationFrequency}</>
                    }
                    inputProps={{
                      max: '999',
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <DatePicker
                    name="membershipEstablished"
                    id="membershipEstablished"
                    label="Membership Established"
                    disabled={!isInternal && !hasBasicInfoPermission}
                    minDate={
                      new Date(BuyerBusinessForm.values.membershipEstablished)
                    }
                    value={BuyerBusinessForm.values.membershipEstablished}
                    errorMessage={BuyerBusinessForm.errors.membershipEstablished?.toString()}
                    onChange={(date) => {
                      BuyerBusinessForm.setFieldValue(
                        'membershipEstablished',
                        date,
                        false
                      )
                    }}
                    country={profileClient?.Country}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <DatePicker
                    name="onSiteAudit"
                    id="onSiteAudit"
                    label="On Site Audit"
                    disabled={!isInternal || !hasBasicInfoPermission}
                    minDate={new Date(BuyerBusinessForm.values.onSiteAudit)}
                    value={BuyerBusinessForm.values.onSiteAudit}
                    errorMessage={BuyerBusinessForm.errors.onSiteAudit?.toString()}
                    onChange={(date) => {
                      BuyerBusinessForm.setFieldValue(
                        'onSiteAudit',
                        date,
                        false
                      )
                    }}
                    country={profileClient?.Country}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mb={12}>
              <Typography variant="h6">PSA Signer (E-Signature)</Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    label="Signer Name"
                    name="psaSignerName"
                    disabled={!isInternal || !hasBasicInfoPermission}
                    onChange={BuyerBusinessForm.handleChange}
                    error={!!BuyerBusinessForm.errors.psaSignerName}
                    value={BuyerBusinessForm.values.psaSignerName || ''}
                    helperText={<>{BuyerBusinessForm.errors.psaSignerName}</>}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    label="Signer Title"
                    name="psaSignerTitle"
                    disabled={!isInternal || !hasBasicInfoPermission}
                    onChange={BuyerBusinessForm.handleChange}
                    error={!!BuyerBusinessForm.errors.psaSignerTitle}
                    value={BuyerBusinessForm.values.psaSignerTitle || ''}
                    helperText={<>{BuyerBusinessForm.errors.psaSignerTitle}</>}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <TextField
                    fullWidth
                    label="Signer E-mail"
                    name="psaSignerEmail"
                    disabled={!isInternal || !hasBasicInfoPermission}
                    onChange={BuyerBusinessForm.handleChange}
                    error={!!BuyerBusinessForm.errors.psaSignerEmail}
                    value={BuyerBusinessForm.values.psaSignerEmail || ''}
                    helperText={<>{BuyerBusinessForm.errors.psaSignerEmail}</>}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        )}
        <Box mb={12}>
          <Typography variant="h6">Consumer Payment Information</Typography>
          <Grid container spacing={8}>
            <Grid item xs={8} lg={8}>
              <TextField
                fullWidth
                multiline
                label="Consumer Payment Instruction"
                name="consumerPaymentInstruction"
                disabled={!isInternal || !hasBasicInfoPermission}
                onChange={BuyerBusinessForm.handleChange}
                error={!!BuyerBusinessForm.errors.consumerPaymentInstruction}
                value={
                  BuyerBusinessForm.values.consumerPaymentInstruction || ''
                }
                helperText={
                  <>
                    Example: Call this number to make a payment (888)-444-2222 x
                    1234
                  </>
                }
              />
            </Grid>
          </Grid>
        </Box>
        {(hasInsurancePermission ||
          hasStatusPermission ||
          (isInternal && hasBasicInfoPermission)) && (
          <Box display="flex" justifyContent="flex-end" my={2}>
            <Button
              startIcon={
                !addOrUpdateBuyerLoading ? (
                  <Save />
                ) : (
                  <CircularProgress size={16} />
                )
              }
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading || addOrUpdateBuyerLoading}
            >
              Save
            </Button>
          </Box>
        )}
      </form>
      <ModalDialog
        header="Name History"
        isOpen={openNameHistory}
        onClose={handleNameHistoryToogle}
        buttonCancelText="Close"
        hideOkButton={true}
        width="600px"
      >
        {buyerBusinessId && (
          <BusinessNameHistory
            businessId={buyerBusinessId}
            refetchQueries={['GetBuyer']}
          />
        )}
      </ModalDialog>
    </>
  )
}

export default BuyerBusinessInformation
